import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ProductAnalysis = lazy(() => import('../components/ProductAnalysis'));

const productAnalysisRoute: RouteObject = {
  path: '/customer-cube/product-analysis',
  element: <ProductAnalysis />,
};

export default productAnalysisRoute;
