import { createSvgIcon } from '@mui/material';

const Reports = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M26.6673 10.667L18.6673 2.66699H8.00065C7.29341 2.66699 6.61513 2.94794 6.11503 3.44804C5.61494 3.94814 5.33398 4.62641 5.33398 5.33366V26.667C5.33398 27.3742 5.61494 28.0525 6.11503 28.5526C6.61513 29.0527 7.29341 29.3337 8.00065 29.3337H24.0007C24.7079 29.3337 25.3862 29.0527 25.8863 28.5526C26.3864 28.0525 26.6673 27.3742 26.6673 26.667V10.667ZM12.0007 25.3337H9.33398V13.3337H12.0007V25.3337ZM17.334 25.3337H14.6673V17.3337H17.334V25.3337ZM22.6673 25.3337H20.0007V21.3337H22.6673V25.3337ZM18.6673 12.0003H17.334V5.33366L24.0007 12.0003H18.6673Z'
      fill='currentColor'
    />
  </svg>,
  'Reports'
);

export default Reports;
