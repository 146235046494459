enum OnBoardingModules {
  Offer = 'offer',
  LMS = 'lms',
  Loyalty = 'loyalty',
  Pwa = 'pwa_setup',
  DigitalReceipt = 'DigitalReceipt',
  CustomerCube = 'CustomerCube',
  CommerceConnect = 'CommerceConnect',
  CampaignDelight = 'CampaignDelight',
  Ecom = 'ecom',
  Survey = 'Survey',
  Chan = 'Chan',
}

export { OnBoardingModules }
