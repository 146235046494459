import { lazy } from "react";
import { RouteObject } from "react-router-dom";


const StoreStatusReport = lazy(() => import('../components/StoreStatusReport'))

const storeStatusReport: RouteObject = {
    path: "reports/store-status-report",
    element: <StoreStatusReport />
}


export default storeStatusReport;