import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const OfferOtpConfig = lazy(() => import('../components/OfferOtpConfig'));

const OfferOtpConfigRoute: RouteObject = {
  path: '/settings/modules/offer-otp',
  element: <OfferOtpConfig />,
};

export default OfferOtpConfigRoute;
