import SaveIcon from '@mui/icons-material/Save';
import {
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';

const SaveIconButton: FC<
  IconButtonProps & { onSave: () => void; loading: boolean }
> = ({ loading, disabled, onSave }) => {
  const handleOnSave = () => {
    if (loading || disabled) return;
    onSave();
  };
  return (
    <Tooltip title={loading ? 'Saving' : 'Save'}>
      <IconButton
        size='small'
        color='secondary'
        disabled={loading || disabled}
        onClick={handleOnSave}
      >
        {!loading && <SaveIcon fontSize='inherit' color='inherit' />}
        {loading && <CircularProgress size={18} color='secondary' />}
      </IconButton>
    </Tooltip>
  );
};

export default SaveIconButton;
