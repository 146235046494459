import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const ProductFrequency = lazy(() => import('../components/ProductFrequency'));

const productFrequencyRoute: RouteObject = {
  path: 'customer-cube/product-frequency',
  element: <ProductFrequency />,
};

export default productFrequencyRoute;
