import Message from "../utils/Message";

export enum MessageEntityType {
  TEXT = 'text',
  MESSAGE_TEMPLATE = 'messageTemplate',
  VISUALIZATION='visualization',
  ERROR = 'error'
}

export enum MessageVisualizationE {
  TABLE = 'table',
  LINE_CHART = 'lineChart',
  BAR_CHART = 'barChart'
}
export interface TextEntity {
  type: MessageEntityType.TEXT;
  value: string;
}
export interface ErrorEntity {
  type: MessageEntityType.ERROR;
  value: string;
}
export interface MessageTemplate {
  type: MessageEntityType.MESSAGE_TEMPLATE;
  value: {
    messageType: 'sms' | 'email';
    name: string;
    variables: string[];
    content: string;
  };
  options?: string[];
}

export interface MessageVisualization {
  type: MessageEntityType.VISUALIZATION;
  value: {
    visualization: MessageVisualizationE;
    axes: any;
    data: any[];
  };
  options?: string[];
}

type MessageEntity = TextEntity | MessageTemplate | MessageVisualization | ErrorEntity;

export type CasaAIResponse = {
  id: string;
  message: MessageEntity[]
}

export type CasaAIHistory = {
  messages: Message[];
  totalMessages: number;
  totalPages: number;
  currentPage: number;
}

export default MessageEntity;
