import _ from 'lodash';
import NavigationLink from '../../../interfaces/NavigationLink';
import SearchHighlight from '../../../interfaces/SearchHighlight';


import { searchHighlight } from '../../../utils';

export interface FuzzySearchNavigationLink extends NavigationLink {
  matches?: SearchHighlight[];
}

export default class FuzzySearch {
  constructor(private readonly list: NavigationLink[]) {}

  query(query: string) {
    const search = (
      list: NavigationLink[],
      query: string
    ): FuzzySearchNavigationLink[] => {
      return list.reduce((acc, val) => {
        const { content, label } = val;

        if (_.isString(content)) {
          const parsedMatch = searchHighlight(label, query);

          if (
            parsedMatch.length === 1 &&
            parsedMatch[0].text.toLowerCase() !== query.toLowerCase()
          )
            return acc;

          acc.push({ ...val, matches: parsedMatch });
        }

        if (_.isArray(content)) {
          const filteredItem = search(content, query);
          if (filteredItem.length) {
            acc.push({ ...val, content: filteredItem });
            return acc;
          }

          const parsedMatch = searchHighlight(label, query);

          if (
            parsedMatch.length === 1 &&
            parsedMatch[0].text.toLowerCase() !== query.toLowerCase()
          )
            return acc;
          acc.push({ ...val, matches: parsedMatch });
        }

        return acc;
      }, [] as FuzzySearchNavigationLink[]);
    };
    return search(this.list, query);
  }
}
