import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CampaignListContainer = lazy(
  () => import('../components/CampaignListContainer')
);

const campaignListRoute: RouteObject = {
  path: 'campaign/list',
  element: <CampaignListContainer />,
};

export default campaignListRoute;
