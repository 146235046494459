import _ from 'lodash';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';

import navConfig from '../constants/navigationLinks';
import useUser from '../hooks/useUser';
import NavigationLink from '../interfaces/NavigationLink';

interface NavListContextType {
  navigationLinks: NavigationLink[];
  allowedPages: string[];
  onActive: (content: NavigationLink) => void;
  fullScreenMode: boolean;
  setFullScreenMode: (value: boolean) => void;
}

const NavigationLinkContext = createContext<NavListContextType>({
  navigationLinks: [],
  allowedPages: [],
  onActive(content) {},
  fullScreenMode: false,
  setFullScreenMode(value) {},
});

export const NavigationLinkProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userPermissions } = useUser();
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const navigationLinks = useMemo(() => {
    const getAllowedNavList = (list: NavigationLink[]) => {
      return list.reduce((acc, val) => {
        const hasPermission = _.isEmpty(
          _.difference(val.requiredPermissions, userPermissions)
        );
        if (!hasPermission) return acc;
        if (typeof val.content === 'string') acc.push(val);
        if (Array.isArray(val.content)) {
          const content = getAllowedNavList(val.content);
          content.length &&
            acc.push({ ...val, content: getAllowedNavList(val.content) });
        }
        return acc;
      }, [] as NavigationLink[]);
    };

    return getAllowedNavList(navConfig);
  }, [userPermissions]);

  const allowedPages = useMemo(() => {
    const getAllowedNavList = (list: NavigationLink[]) => {
      return list.reduce((acc, val) => {
        const hasPermission = _.isEmpty(
          _.difference(val.requiredPermissions, userPermissions)
        );

        if (!hasPermission) return acc;
        if (typeof val.content === 'string') acc.push(val.content);
        if (_.isArray(val.content)) acc.push(...getAllowedNavList(val.content));

        return acc;
      }, [] as string[]);
    };

    return getAllowedNavList(navConfig);
  }, [userPermissions]);

  const handleOnActivePage = (content: NavigationLink) => {
    window.document.title = `${content.label} - Casaretail.in`;
  };

  return (
    <NavigationLinkContext.Provider
      value={{
        navigationLinks,
        allowedPages,
        setFullScreenMode,
        onActive: handleOnActivePage,
        fullScreenMode,
      }}
    >
      {children}
    </NavigationLinkContext.Provider>
  );
};

export default NavigationLinkContext;
