import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { TextEntity } from '../../../interfaces/MessageEntity';

const MessageEntityText: FC<TextEntity> = ({ value }) => {
  return (
    <Box>
      <Typography>{value}</Typography>
    </Box>
  );
};

export default MessageEntityText;
