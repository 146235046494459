import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { CustomerContextProvider } from "../context/Customer360Context";

const Customer360 = lazy(() => import("../components/Customer360"));

const customer360POS: RouteObject = {
  path: "/pos-dashboard/customer-cube/customer-360",
  element: (
    <CustomerContextProvider>
      <Customer360 isPos={true} />
    </CustomerContextProvider>
  ),
};

export default customer360POS;
