import Keycloak from 'keycloak-js'

import Axios from './axios'
import config from './config'

const keycloak = new Keycloak(config.keycloakConfig)

keycloak.onTokenExpired = async () => {
  console.log('expired ' + new Date())

  try {
    await keycloak.updateToken(100)
    const token = getAccessToken()
    if (!token) throw new Error('Invalid')
    Axios.setAuthorization(getAccessToken()!)
  } catch (error) {
    console.log(`not refreshed :: ` + new Date())
    await forceLogin()
  }
}

export const initialize = () => {
  return keycloak.init({
    onLoad: 'login-required',
  })
}

export const redirectToLogin = () => {
  keycloak.clearToken()
  keycloak.login()
}

export const logout = () => {
  return keycloak.logout()
}

export const forceLogin = () =>
  Promise.allSettled([logout, redirectToLogin].map((fn) => fn()))

export const getAccessToken = () => {
  return keycloak.token
}

export default keycloak
