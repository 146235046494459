import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const OmniChannelDashboard = lazy(
  () => import("../OmniChannelDashboard")
);

const omniChannelDashboardRoute: RouteObject = {
  path: "/omni-channel-dashboard",
  element: <OmniChannelDashboard />,
};

export default omniChannelDashboardRoute;
