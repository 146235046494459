import { List } from '@mui/material';
import React, { FC } from 'react';
import { classnames } from '../../../../utils';

import { FuzzySearchNavigationLink } from '../../utils/FuzzySearch';
import InstantSearchListItem from '../InstantSearchListItem/InstantSearchListItem';
import styles from './InstantSearchList.module.scss';
interface InstantSearchListProps {
  list: FuzzySearchNavigationLink[];
  isParentElement?: boolean;
  onClose: () => void;
}

const InstantSearchList: FC<InstantSearchListProps> = ({
  list,
  isParentElement = false,
  onClose,
}) => {
  return (
    <List
      id={styles.instantSearchList}
      className={classnames({ [styles.parentElement]: isParentElement })}
    >
      {list.map((item) => (
        <InstantSearchListItem
          key={item.label}
          {...item}
          isParentElement={isParentElement}
          onClose={onClose}
        />
      ))}
    </List>
  );
};

export default InstantSearchList;
