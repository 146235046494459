import CasaAISession from "../../interfaces/CasaAiSession";
import React, {FC, useState} from "react";
import {Box, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import styles from './CasaAIHistory.module.scss'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import TextField from "@mui/material/TextField/TextField";
import useCasaAI from "../../hooks/useCasaAI";
import {updateSessionName} from "../../services";
import useUser from "../../../../hooks/useUser";
import {isUndefined, pick} from "lodash";

export const SessionItem: FC<CasaAISession> = (session) => {
  const {id, name} = session;
  const {setActiveSessionBySession, setSessions, sessions, loadMessagesBySessionAndPage, activeSession, module} = useCasaAI();
  const { selectedBusiness } = useUser();
  const [isVertVisible, setIsVertVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState(name);
  const open = Boolean(anchorEl);

  const handleClick = () => {
    setIsVertVisible(true);
  };

  const handleClose = () => {
    setIsVertVisible(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const switchSession = () => {
    setSessions(sessions.concat(session));
    setActiveSessionBySession(session);
    loadMessagesBySessionAndPage(id, 1);
  }

  const isSelectedSession = () => {
    return activeSession?.id === session.id
  }

  const onSubmitName = () => {
    setIsEditing(false);
    if (textValue === '' || isUndefined(textValue)){
      setTextValue(name);
      return;
    }
    updateSessionName(pick(selectedBusiness, ['id', 'name']), id, textValue, module)
  }
  return (
    <Box className={`${styles.sessionItemContainer} ${isVertVisible ? styles.gridContainerFaded : ''} ${isSelectedSession() ? styles.selectedSession : ''}`} onMouseEnter={handleClick} onMouseLeave={handleClose}>
      {isEditing ? (
          <TextField
            id={"editable-textfield"}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={() => {onSubmitName()}}
          />
        ) : (
        <Box style={{position: 'relative', height: '24px'}} onClick={() => switchSession()}>
          <div className={styles.fadeText}>{textValue}</div>
          <Box className={styles.fadeGradient}></Box>
        </Box>
        )}
      {isVertVisible && (
        <MoreHorizIcon
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}/>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize={'small'}/>
          </ListItemIcon>
          <ListItemText>
            Rename
          </ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}