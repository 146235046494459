import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const Channels = lazy(() => import("../components/Muffler"));

const MufflerRoute: RouteObject = {
  path: "settings/modules/muffler-config",
  element: <Channels />,
};

export default MufflerRoute;
