import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ExclusionContainer = lazy(() => import("../components/Exclusion"));

const ExclusionRoute: RouteObject = {
  path: "settings/modules/retensee/exclusion",
  element: <ExclusionContainer />,
};

export default ExclusionRoute;
