import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';

import useCasaAI from '../../hooks/useCasaAI';
import styles from './CasaAIHeader.module.scss';
import CasaAIModule from '../../interfaces/CasaAIModule';
import { casaAIModuleOptions } from '../../services';
import useUser from '../../../../hooks/useUser';
import { difference, isEmpty } from 'lodash';

const CasaAIHeader = () => {
  const { createSession, isCreatingSession, module, setModule } = useCasaAI();
  const { userPermissions } = useUser();

  const handleCreateSession = () => {
    if (isCreatingSession) return;

    createSession();
  };
  const handleModuleChange = ({ target }: SelectChangeEvent<CasaAIModule>) => {
    const chosenModule = target?.value as CasaAIModule;
    setModule(chosenModule);
  };

  return (
    <Box id={styles.casaAIHeader}>
      <Box style={{ margin: '10px 15px' }}>
        <Select
          value={module}
          SelectDisplayProps={{ style: { paddingBlock: 6.5 } }}
          onChange={handleModuleChange}
          sx={{ width: '100%' }}
          size={'small'}
        >
          {casaAIModuleOptions.map(({ label, value, permissions }) =>
            isEmpty(difference(permissions, userPermissions)) ? (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ) : null
          )}
        </Select>
      </Box>
      <Box className={styles.createSession}>
        <Tooltip
          title={isCreatingSession ? 'Creating Session' : 'Create Session'}
        >
          <span>
            <IconButton
              color='primary'
              disabled={isCreatingSession}
              onClick={handleCreateSession}
            >
              {!isCreatingSession && <AddCircleIcon fontSize='inherit' />}
              {isCreatingSession && (
                <CircularProgress color='primary' size={24} />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
export default CasaAIHeader;
