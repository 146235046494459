import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const StoreAnalysis = lazy(() => import('../components/StoreAnalysis'));

const storeAnalysis: RouteObject = {
  path: '/customer-cube/store-analysis',
  element: <StoreAnalysis />,
};

export default storeAnalysis;
