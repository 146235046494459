import { Box, Card, Typography } from '@mui/material';
import { FC } from 'react';

import useCasaAI from '../../hooks/useCasaAI';
import styles from './MessageEntity.module.scss';

interface MessageOptionsProps {
  options: string[];
}

const MessageOptions: FC<MessageOptionsProps> = ({ options }) => {
  const { addUserMessage, activeSession } = useCasaAI();

  const handleAddMessageOption = (option: string) => {
    if (!activeSession) return;
    addUserMessage(activeSession.id, option, true);
  };

  return (
    <Box id={styles.options}>
      {options.map((option) => (
        <Card
          key={option}
          className={styles.option}
          onClick={handleAddMessageOption.bind(null, option)}
        >
          <Typography className={styles.optionContent}>{option}</Typography>
        </Card>
      ))}
    </Box>
  );
};
export default MessageOptions;
