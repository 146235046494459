import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import useNavigationLink from '../../../../hooks/useNavigationLink';

import NavigationLink from '../../../../interfaces/NavigationLink';
import SearchNotFound from '../../../../shared/icons/SearchNotFound';
import CasaLogo from '../../../../shared/logo/CasaLogo';
import FuzzySearch from '../../utils/FuzzySearch';
import InstantSearchHeader from '../InstantSearchHeader/InstantSearchHeader';
import InstantSearchList from '../InstantSearchList/InstantSearchList';
import styles from './InstantSearchDialog.module.scss';

interface InstantSearchDialogProps {
  onClose: () => void;
}

const InstantSearchDialog: FC<InstantSearchDialogProps> = ({ onClose }) => {
  const [enteredSearchTerm, setEnteredSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState<NavigationLink[]>([]);
  const { navigationLinks } = useNavigationLink();
  const fuzzySearch = useMemo(
    () => new FuzzySearch(navigationLinks),
    [navigationLinks]
  );

  useLayoutEffect(() => {
    setSearchResult(fuzzySearch.query(enteredSearchTerm));
  }, [enteredSearchTerm, fuzzySearch]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth='md'
      fullWidth
      PaperProps={{ sx: { height: '75%' } }}
      id={styles.instantSearchDialog}
    >
      <InstantSearchHeader
        searchTerm={enteredSearchTerm}
        onSearchTermChange={setEnteredSearchTerm}
        onClose={onClose}
      />
      <DialogContent dividers sx={{ py: 1, px: 2.5 }}>
        <InstantSearchList
          list={searchResult}
          isParentElement
          onClose={onClose}
        />
        {enteredSearchTerm && searchResult.length === 0 && (
          <Box className={styles.noResultFound}>
            <SearchNotFound fontSize='large' color='disabled' />
            <Typography>
              No results for "<strong>{enteredSearchTerm}</strong>"
            </Typography>
          </Box>
        )}
      </DialogContent>
      <Box component='footer' className={styles.instantSearchFooter}>
        <CasaLogo width={60} />
      </Box>
    </Dialog>
  );
};

export default InstantSearchDialog;
