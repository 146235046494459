import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { isString } from 'lodash';
import React, { FC, useState } from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import useUser from '../../../../hooks/useUser';
import NavigationLink from '../../../../interfaces/NavigationLink';
import { classnames } from '../../../../utils';
import SidebarList from '../SidebarList/SidebarList';
import styles from './SidebarListItem.module.scss';

interface SidebarListItemProps extends NavigationLink {
  isParentElement?: boolean;
  onCloseSidebar: () => void;
  onActive: (content: NavigationLink) => void;
}

const SidebarListItem: FC<SidebarListItemProps> = ({
  label,
  content,
  icon: Icon,
  isParentElement = false,
  externalLink,
  isNew,
  disabled,
  onCloseSidebar,
  onActive,
  requiredPermissions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useUser();
  const { pathname } = useLocation();

  const handleCollapseToggle = (): void => {
    setIsOpen((isOpen) => !isOpen);
  };

  const listItemIcon = isParentElement && Icon && (
    <ListItemIcon className={styles.listItemIcon}>
      <Icon color='primary' />
    </ListItemIcon>
  );

  // TODO: Need to add DUO link for handling external link and internal link - This is temp fix
  if (isString(content) && externalLink) {
    const src = new URL(content);
    user?.selectedBusiness?.id &&
      src.searchParams.append('bu', user.selectedBusiness.id.toString());
    src.searchParams.append('ref', 'v2');

    return (
      <ListItemButton
        target='_blank'
        disableRipple
        id={styles.sidebarListItem}
        href={src.href}
        onClick={onCloseSidebar}
        disabled={disabled}
      >
        {listItemIcon}
        <ListItemText primary={label} sx={{ color: 'secondary.dark' }} />
        {isNew ? <FiberNewIcon color='primary' /> : null}
      </ListItemButton>
    );
  }

  if (typeof content === 'string' && matchPath(content, pathname))
    onActive({
      label,
      content,
      icon: Icon,
      externalLink,
      isNew,
      disabled,
      requiredPermissions,
    });

  if (isString(content))
    return (
      <ListItemButton
        disableRipple
        id={styles.sidebarListItem}
        component={NavLink}
        end
        to={content}
        disabled={disabled}
        onClick={onCloseSidebar}
        
      >
        {listItemIcon}
        <ListItemText
          className={styles.sidebarListItemText}
          primaryTypographyProps={{
            className: styles.sidebarListItemTypography,
          }}
          primary={label}
          sx={{ color: 'secondary.dark' }}
        />
        {isNew ? <FiberNewIcon color='primary' /> : null}
      </ListItemButton>
    );

  if (content.length === 0) return null;

  const getActiveListItem = (list: NavigationLink[]): NavigationLink | null => {
    let selectedItem: NavigationLink | null = null;
    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      if (
        typeof item.content === 'string' &&
        matchPath(item.content, pathname)
      ) {
        selectedItem = item;
        break;
      } else if (Array.isArray(item.content))
        return getActiveListItem(item.content);
    }

    return selectedItem;
  };

  const activeListItem = getActiveListItem(content);

  return (
    <>
      <ListItemButton
        disableRipple
        id={styles.sidebarListItem}
        onClick={handleCollapseToggle}
        disabled={disabled}
      >
        {listItemIcon}
        <ListItemText primary={label} sx={{ color: 'secondary.dark' }} />
        {isNew ? <FiberNewIcon color='primary' /> : null}
        <ChevronRightIcon
          fontSize='small'
          color='disabled'
          className={classnames(styles.arrowIcon, {
            [styles.active]: isOpen,
          })}
        />
      </ListItemButton>
      <Collapse in={isOpen} mountOnEnter>
        <SidebarList
          onCloseSidebar={onCloseSidebar}
          onActive={onActive}
          list={content}
        />
      </Collapse>
      {!isOpen && !!activeListItem && (
        <ListItemButton
          disableRipple
          id={styles.sidebarListItem}
          className={styles.activeListItem}
        >
          <ListItemText
            className={styles.sidebarListItemText}
            primaryTypographyProps={{
              className: styles.sidebarListItemTypography,
            }}
            primary={activeListItem.label as string}
            sx={{ color: 'secondary.dark' }}
          />
        </ListItemButton>
      )}
    </>
  );
};

export default SidebarListItem;
