import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const BrandTheme = lazy(() => import('../components'))

const BrandThemeRoute: RouteObject = {
  path: 'settings/brand-theme',
  element: <BrandTheme />,
}

export default BrandThemeRoute
