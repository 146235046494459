import { List } from '@mui/material';
import React, { FC } from 'react';

import NavigationLink from '../../../../interfaces/NavigationLink';
import { classnames } from '../../../../utils';
import SidebarListItem from '../SidebarListItem/SidebarListItem';
import styles from './SidebarList.module.scss';

interface SidebarListProps {
  list: NavigationLink[];
  isParentElement?: boolean;
  onCloseSidebar: () => void;
  onActive: (content: NavigationLink) => void;
}

const SidebarList: FC<SidebarListProps> = ({
  list,
  isParentElement = false,
  onCloseSidebar,
  onActive,
}) => {
  return (
    <List
      id={styles.sidebarList}
      className={classnames({ [styles.parentElement]: isParentElement })}
    >
      {list.map((navListItem) => (
        <SidebarListItem
          key={navListItem.label}
          {...navListItem}
          onActive={onActive}
          onCloseSidebar={onCloseSidebar}
          isParentElement={isParentElement}
        />
      ))}
    </List>
  );
};

export default SidebarList;
