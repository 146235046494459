import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const StoreManagement = lazy(() => import("../components/StoreManagement"));

const StoreManagementRoute: RouteObject = {
  path: "/settings/store-management",
  element: <StoreManagement />,
};

export default StoreManagementRoute;