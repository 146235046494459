import { createSvgIcon } from '@mui/material';

const RoleManagement = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M16.8001 26.3997C16.8001 26.533 16.8001 26.6663 16.8001 26.6663H2.80013C2.13346 26.6663 1.4668 25.9997 1.4668 25.333V23.9997C1.4668 20.3997 8.53346 18.6663 12.1335 18.6663C13.8668 18.6663 16.4001 19.0663 18.5335 19.8663L16.8001 21.5997V26.3997ZM12.1335 15.9997C15.0668 15.9997 17.4668 13.5997 17.4668 10.6663C17.4668 7.73301 15.0668 5.33301 12.1335 5.33301C9.20013 5.33301 6.80013 7.73301 6.80013 10.6663C6.80013 13.5997 9.20013 15.9997 12.1335 15.9997ZM30.0001 16.7997L28.4001 15.1997C27.6001 14.3997 26.4001 14.3997 25.6001 15.1997L20.4001 20.3997L18.6668 22.133V25.8663C18.6668 26.2663 18.9335 26.533 19.3335 26.533H22.0001H23.0668L30.1335 19.4663C30.8001 18.7997 30.8001 17.5997 30.0001 16.7997Z'
      fill='currentColor'
    />
  </svg>,
  'RoleManagement'
);

export default RoleManagement;
