const currencyType = {
  IND: {
    currency: "INR",
    locale: "en-IN",
  },
  USA: {
    currency: "USD",
    locale: "en-us",
  },
};

const formatNumber = (
  number: number,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = "en-IN"
): string => {
  let updatedCurrency = options.currency;
  let updatedLocale = locale;
  Object.entries(currencyType).forEach(([key, value]) => {
    if (key === options.currency) {
      updatedCurrency = value.currency;
      updatedLocale = value.locale;
    }
  });
  return Intl.NumberFormat(updatedLocale, {
    ...options,
    currency: updatedCurrency,
  }).format(number);
};

export default formatNumber;
