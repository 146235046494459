import navConfig from '../constants/navigationLinks'
import NavigationLink from '../interfaces/NavigationLink'

type NavigationLinkMap = Record<string, NavigationLink>
const DEFAULT_RENDER_CASA_AI = true

const _reduceRouteConfig = (navConfig: NavigationLink[]): NavigationLinkMap => {
  const reducedNavigationLinks = navConfig.reduce((acc, val) => {
    if (typeof val.content === 'string')
      return {
        ...acc,
        [val.content]: val,
      }

    if (Array.isArray(val.content))
      return {
        ...acc,
        ..._reduceRouteConfig(val.content),
      }
    return acc
  }, {} as NavigationLinkMap)

  return reducedNavigationLinks
}

export const shouldRenderCasaAiByRoute = (
  path: string,
  patternsToOmitOtherThanNavigation = ['/invoice']
) => {
  if (
    patternsToOmitOtherThanNavigation.some((pattern) => path.includes(pattern))
  ) {
    return false
  }
  const routeConfig = _reduceRouteConfig(navConfig)
  if (routeConfig[path]) {
    return routeConfig[path].showCasaAi ?? DEFAULT_RENDER_CASA_AI
  }

  return DEFAULT_RENDER_CASA_AI
}
