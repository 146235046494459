import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Personify = lazy(() => import('../components/Personify'));

const PersonifyConfigRoute: RouteObject = {
  path: '/settings/modules/personify',
  element: <Personify />,
};

export default PersonifyConfigRoute;
