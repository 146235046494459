import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { generalSettings, moduleSettings } from '../services';

const Settings = lazy(() => import('../components/Settings'));
const SettingsList = lazy(
  () => import('../components/SettingsList/SettingsList')
);

const settingsRoute: RouteObject = {
  path: 'settings',
  element: <Settings />,
  children: [
    {
      index: true,
      element: <SettingsList settings={generalSettings} />,
    },
    {
      path: 'modules',
      element: <SettingsList settings={moduleSettings} />,
    },
  ],
};

export default settingsRoute;
