import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';

import useUser from '../../../../hooks/useUser';
import styles from './BrandAndBusinessSelect.module.scss';

const BrandAndBusinessSelect = () => {
  const { businesses, selectedBusiness, setBusiness, disableBusinessSwitch } = useUser();

  const handleBusinessChange = (event: SelectChangeEvent) => {
    setBusiness(+event.target.value);
  };

  return (
    <FormControl id={styles.brandSelectFormControl} size='small'>
      <InputLabel id='brand'>Business</InputLabel>
      <Select
        disabled={disableBusinessSwitch}
        labelId='brand'
        label='Business'
        value={selectedBusiness?.id.toString()}
        sx={{ fontSize: 'inherit' }}
        SelectDisplayProps={{ style: { paddingBlock: 7 } }}
        onChange={handleBusinessChange}
      >
        {businesses.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {_.capitalize(name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BrandAndBusinessSelect;
