import { createSvgIcon } from '@mui/material';

const Otp = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M9.60078 13.5996C9.38861 13.5996 9.18513 13.6839 9.0351 13.8339C8.88507 13.984 8.80078 14.1874 8.80078 14.3996V17.5996C8.80078 17.8118 8.88507 18.0153 9.0351 18.1653C9.18513 18.3153 9.38861 18.3996 9.60078 18.3996C9.81295 18.3996 10.0164 18.3153 10.1665 18.1653C10.3165 18.0153 10.4008 17.8118 10.4008 17.5996V14.3996C10.4008 14.1874 10.3165 13.984 10.1665 13.8339C10.0164 13.6839 9.81295 13.5996 9.60078 13.5996ZM21.6008 15.1996H22.4008C22.613 15.1996 22.8164 15.1153 22.9665 14.9653C23.1165 14.8153 23.2008 14.6118 23.2008 14.3996C23.2008 14.1874 23.1165 13.984 22.9665 13.8339C22.8164 13.6839 22.613 13.5996 22.4008 13.5996H21.6008V15.1996Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 6.4C4 5.76348 4.25286 5.15303 4.70294 4.70294C5.15303 4.25286 5.76348 4 6.4 4H25.6C26.2365 4 26.847 4.25286 27.2971 4.70294C27.7471 5.15303 28 5.76348 28 6.4V25.6C28 26.2365 27.7471 26.847 27.2971 27.2971C26.847 27.7471 26.2365 28 25.6 28H6.4C5.76348 28 5.15303 27.7471 4.70294 27.2971C4.25286 26.847 4 26.2365 4 25.6V6.4ZM7.2 14.4C7.2 14.0848 7.26208 13.7727 7.38269 13.4816C7.5033 13.1904 7.68008 12.9258 7.90294 12.7029C8.1258 12.4801 8.39038 12.3033 8.68156 12.1827C8.97274 12.0621 9.28483 12 9.6 12C9.91517 12 10.2273 12.0621 10.5184 12.1827C10.8096 12.3033 11.0742 12.4801 11.2971 12.7029C11.5199 12.9258 11.6967 13.1904 11.8173 13.4816C11.9379 13.7727 12 14.0848 12 14.4V17.6C12 17.9152 11.9379 18.2273 11.8173 18.5184C11.6967 18.8096 11.5199 19.0742 11.2971 19.2971C11.0742 19.5199 10.8096 19.6967 10.5184 19.8173C10.2273 19.9379 9.91517 20 9.6 20C9.28483 20 8.97274 19.9379 8.68156 19.8173C8.39038 19.6967 8.1258 19.5199 7.90294 19.2971C7.68008 19.0742 7.5033 18.8096 7.38269 18.5184C7.26208 18.2273 7.2 17.9152 7.2 17.6V14.4ZM15.2 13.6H13.6V12H18.4V13.6H16.8V20H15.2V13.6ZM20 12H22.4C23.0365 12 23.647 12.2529 24.0971 12.7029C24.5471 13.153 24.8 13.7635 24.8 14.4C24.8 15.0365 24.5471 15.647 24.0971 16.0971C23.647 16.5471 23.0365 16.8 22.4 16.8H21.6V20H20V12Z'
      fill='currentColor'
    />
  </svg>,
  'Otp'
);

export default Otp;
