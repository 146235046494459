import {FC} from "react";
import Box from "@mui/material/Box/Box";
import {MessageEntityTable} from "./table";
import {MessageVisualizationE} from "../../../../interfaces/MessageEntity";
import {MessageVisualizationBar} from "./BarChart";
import {MessageVisualizationLine} from "./LineChart";

interface MessageEntityVisualizationProps {
  value: {
    axes: any;
    visualization: MessageVisualizationE;
    data: any;
  }
}
export const MessageEntityVisualization: FC<MessageEntityVisualizationProps & { showOptions: boolean }> = ({value, showOptions}) => {
  const getVisualizationComponent = () => {
    switch (value.visualization) {
      case MessageVisualizationE.LINE_CHART:
        return <MessageVisualizationLine data={value?.data} axes={value?.axes} />
      case MessageVisualizationE.BAR_CHART:
        return <MessageVisualizationBar data={value?.data} axes={value?.axes} />
      case MessageVisualizationE.TABLE:
        return <MessageEntityTable headers={value?.axes?.columns} data={value?.data}/>
      default:
        return <MessageEntityTable headers={value?.axes?.columns} data={value?.data}/>
    }
  }
  return (
    <Box>
      {getVisualizationComponent()}
    </Box>
  )
}
