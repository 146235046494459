import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const FlipSellLazy = lazy(() => import("../components/FlipSell"));
const FlipSellListLazy = lazy(
  () => import("../components/FlipSellList/FlipSellList")
);
const FlipBookPreviewLazy = lazy(
  () => import("../components/FlipBookPreview/FlipBookPreview")
);
const FlipBookEditLazy = lazy(
  () => import("../components/FlipBookEdit/FlipBookEdit")
);

const FlipSellRoute: RouteObject = {
  path: "flipsell",
  element: <FlipSellLazy />,
  children: [
    {
      path: "",
      element: <FlipSellListLazy />,
    },

    {
      path: "preview/:id",
      element: <FlipBookPreviewLazy />,
    },

    {
      path: "edit/:id",
      element: <FlipBookEditLazy />,
    },
  ],
};

export default FlipSellRoute;
