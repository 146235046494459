import axios from 'axios';

import config from '../../../config';
import BusinessUnit from '../../../interfaces/BusinessUnit';
import CopyWriteTemplate from '../../CopyWriteTemplate/interfaces/CopyWriteTemplate';
import CasaAIModule from '../interfaces/CasaAIModule';
import CasaAISession from '../interfaces/CasaAiSession';
import MessageUser from '../interfaces/MessageUser';
import Message from '../utils/Message';
import { CasaAIHistory, CasaAIResponse } from '../interfaces/MessageEntity';
import {
  CasaAIFeedbackResponse,
  FeedbackTypes,
} from '../interfaces/CasaAIFeedback';
import { CasaAiGroupedSession } from '../interfaces/CasaAIGroupedSession';
import Permissions from '../../../shared/enums/Permissions';

const { apiServerUrl } = config;

export const casaAIModuleOptions = [
  {
    label: 'Insights',
    value: CasaAIModule.GENERATIVE,
    permissions: [Permissions.INSIGHTS]
  },
  {
    label: 'Copy Write',
    value: CasaAIModule.COPY_WRITE,
    permissions: [Permissions.COPY_WRITE]
  },
];

export const createNewSession = (business: BusinessUnit, module: string) => {
  return axios
    .get(`${apiServerUrl}/users/casa-ai/${business.id}/session/${module}/new`)
    .then((response) => {
      return response.data.data as CasaAISession;
    });
};

export const getLastActiveOrNewSession = (
  business: BusinessUnit,
  module: string
) => {
  return axios
    .get(
      `${apiServerUrl}/users/casa-ai/${business.id}/session/${module}/active`
    )
    .then((response) => {
      return response.data.data as CasaAISession;
    });
};
export const getMessageHistoryBySession = (
  business: BusinessUnit,
  module: string,
  sessionId: string,
  page: number
) => {
  return axios
    .post<CasaAIHistory>(`${apiServerUrl}/users/casa-ai/history/session`, {
      page: page,
      businessUnit: business,
      sessionId,
      module: module,
    })
    .then(({ data }) => {
      return data;
    });
};
export const getCasaAIReply = (
  query: string,
  business: BusinessUnit,
  sessionId: string,
  module: CasaAIModule,
  isCustomized: boolean = false
): Promise<Message> => {
  return axios
    .post<CasaAIResponse>(`${apiServerUrl}/users/casa-ai/message`, {
      message: query,
      businessUnit: business,
      sessionId,
      module,
      isCustomized
    })
    .then(({ data }) => new Message(MessageUser.AI, data));
};

export const saveMessageTemplate = (
  business: BusinessUnit,
  template: Pick<CopyWriteTemplate, 'name' | 'content' | 'channel'>
) => {
  return axios.post(
    `${apiServerUrl}/users/copy-write/business/${business.id}/templates`,
    template
  );
};

export const getFeedbackForMessage = (
  business: BusinessUnit,
  messageId: string
) => {
  return axios
    .get<CasaAIFeedbackResponse>(
      `${apiServerUrl}/users/casa-ai/${business.id}/feedback/message/${messageId}`
    )
    .then((res) => res.data);
};

export const saveFeedbackForMessage = (
  business: BusinessUnit,
  messageId: string,
  feedbackType: FeedbackTypes
) => {
  return axios
    .post<CasaAIFeedbackResponse>(
      `${apiServerUrl}/users/casa-ai/feedback/message`,
      {
        messageId: messageId,
        businessUnit: business,
        feedbackType: feedbackType,
      }
    )
    .then((res) => res.data);
};

export const getLastNSessionByModule = (
  business: BusinessUnit,
  module: CasaAIModule
) => {
  // This api can fetch atmost 100 sessions (No pagination)
  // If numberOfSessions not provided, by default it will pick 25 sessions.
  return axios
    .get(
      `${apiServerUrl}/users/casa-ai/${business.id}/session/${module}/list?numberOfSessions=25`
    )
    .then((response) => {
      return response.data.data as CasaAiGroupedSession;
    });
};

export const updateSessionName = (
  business: BusinessUnit,
  sessionId: string,
  name: string,
  module: CasaAIModule
) => {
  return axios
    .post<CasaAIFeedbackResponse>(
      `${apiServerUrl}/users/casa-ai/${business.id}/session/${module}/name`,
      {
        sessionId: sessionId,
        businessUnit: business,
        name: name,
      }
    )
    .then((res) => res.data);
};
