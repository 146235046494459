import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Error404Image from '../../assets/images/Error404Image';
import Navbar from '../../layouts/Dashboard/components/Navbar/Navbar';
import styles from './Error404.module.scss';

const Error404 = () => {
  const navigate = useNavigate();

  const handleNavigateToHome = () => {
    navigate('/', { replace: true });
  };

  useEffect(() => {
    window.document.title = '404 Not Found - Casaretail.in';
  }, []);

  return (
    <Box id={styles.error404}>
      <Navbar onToggleSidebar={() => {}} hideSidebar />
      <Box className={styles.error404Body}>
        <Box>
          <Error404Image />
        </Box>
        <Box className={styles.error404Info}>
          <Typography className={styles.error404InfoTitle} variant='h3'>
            Oops! Page Not Found
          </Typography>
          <Typography className={styles.error404InfoDescription}>
            This page doesn't exist or was removed! <br />
            We suggest you back to home or hit{' '}
            <Typography component='code' color='primary.main'>
              ctrl + k
            </Typography>{' '}
            to search.
          </Typography>
          <Button size='large' onClick={handleNavigateToHome}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default Error404;
