import { createSvgIcon } from '@mui/material';

const Blacklist = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M5.5 25C5.5 25 4 25 4 23.5C4 22 5.5 17.5 13 17.5C21.7278 17.5 17 22.5 17 24C17 25.5 15.5 25 15.5 25H5.5ZM13 16C14.1935 16 15.3381 15.5259 16.182 14.682C17.0259 13.8381 17.5 12.6935 17.5 11.5C17.5 10.3065 17.0259 9.16193 16.182 8.31802C15.3381 7.47411 14.1935 7 13 7C11.8065 7 10.6619 7.47411 9.81802 8.31802C8.97411 9.16193 8.5 10.3065 8.5 11.5C8.5 12.6935 8.97411 13.8381 9.81802 14.682C10.6619 15.5259 11.8065 16 13 16Z'
      fill='currentColor'
    />
    <circle cx='20' cy='21' r='6' fill='white' />
    <path
      d='M20.0021 16C17.2448 16 15 18.2748 15 21.0473C15 23.8198 17.2448 26.0905 20.0021 26.0905C22.7593 26.0905 25 23.8198 25 21.0473C25 18.2748 22.7593 16 20.0021 16ZM20.0021 17.6701C21.846 17.6701 23.3299 19.1686 23.3299 21.0473C23.3299 22.926 21.846 24.4204 20.0021 24.4204C18.1581 24.4204 16.6701 22.926 16.6701 21.0473C16.6701 19.1686 18.1581 17.6701 20.0021 17.6701Z'
      fill='currentColor'
    />
    <path
      d='M19.118 19.3115C19.0082 19.3111 18.8994 19.3323 18.7978 19.374C18.6963 19.4156 18.6039 19.4769 18.526 19.5542C18.3684 19.7098 18.279 19.9215 18.2774 20.1429C18.2757 20.3643 18.362 20.5773 18.5172 20.7351L18.8242 21.0453L18.5172 21.3566C18.3623 21.5144 18.2763 21.7272 18.2779 21.9483C18.2795 22.1694 18.3687 22.3809 18.526 22.5364C18.6832 22.6919 18.8956 22.7787 19.1168 22.7779C19.3379 22.7771 19.5497 22.6887 19.7057 22.532L20.0007 22.2349L20.2946 22.532C20.4506 22.6887 20.6624 22.7771 20.8835 22.7779C21.1047 22.7787 21.3171 22.6919 21.4744 22.5364C21.6316 22.3809 21.7207 22.1694 21.7224 21.9483C21.724 21.7272 21.638 21.5144 21.483 21.3566L21.1761 21.0453L21.483 20.7351C21.6383 20.5773 21.7245 20.3643 21.7229 20.1429C21.7213 19.9215 21.6319 19.7098 21.4744 19.5542C21.3964 19.4769 21.304 19.4156 21.2025 19.374C21.1009 19.3323 20.9921 19.3111 20.8823 19.3115C20.6614 19.313 20.4501 19.4018 20.2946 19.5586L20.0007 19.8568L19.7057 19.5586C19.5502 19.4018 19.3389 19.313 19.118 19.3115Z'
      fill='currentColor'
    />
  </svg>,
  'Blacklist'
);

export default Blacklist;
