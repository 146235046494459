import {lowerCase, upperFirst} from "lodash";

export const beautifyHeader = (value: string) => {
  //replace _ with space
  let updatedValue = value.replace("_", " ");
  //lower case all characters
  updatedValue = lowerCase(updatedValue);
  //upper case first letter alone
  updatedValue = upperFirst(updatedValue);
  return updatedValue;
}