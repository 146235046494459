import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import CasaAISession from "../../interfaces/CasaAiSession";
import {SessionItem} from "./SessionItem";
import styles from './CasaAIHistory.module.scss'

interface GroupItemProps {
  groupName: string;
  groupedSessions: CasaAISession[];
}
export const GroupItem:FC<GroupItemProps> = ({groupName, groupedSessions}) => {
  return (
    <Box className={styles.groupedItemContainer}>
      <Typography variant='subtitle1' color='text.secondary'>{groupName}</Typography>
      <Box>
        {groupedSessions.map(session => (<SessionItem {...session}/>))}
      </Box>
    </Box>
  )
}