import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CasaFields = lazy(() => import('../components/TenantFields'));

const casaFieldsRoute: RouteObject = {
  path: 'tenant-fields-mapping',
  element: <CasaFields />,
};

export default casaFieldsRoute;
