import { Box, Typography } from '@mui/material';

import CasaAI from '../../../../shared/icons/CasaAI';
import useCasaAI from '../../hooks/useCasaAI';
import CasaAISuggestions from '../CasaAISuggestions/CasaAISuggestions';
import styles from './CasaAIInfo.module.scss';

const CasaAIInfo = () => {
  const { aiName } = useCasaAI();
  return (
    <Box id={styles.casaAIInfo}>
      <Box className={styles.about}>
        <CasaAI />
        <Box>
          <Typography variant='h5'>
            Empower your message with {aiName} AI
          </Typography>
        </Box>
      </Box>
      <CasaAISuggestions />
    </Box>
  );
};
export default CasaAIInfo;
