import { Box, Card, CardContent } from '@mui/material';

import useCasaAI from '../../hooks/useCasaAI';
import styles from './CasaAISuggestions.module.scss';
import CasaAIModule from "../../interfaces/CasaAIModule";

const suggestions = {
  [CasaAIModule.COPY_WRITE]: [
    'Write a Thank you sms for new customer and give them a coupon code',
    'Write a email in html for end of season sale with 20% off',
    'Write a sms for losing customers',
    'Write a email for end of season sale',
  ],
  [CasaAIModule.GENERATIVE]: [
    "Give me the total sales for current year",
    "Compare the sales of last two years as bar chart",
    "Find the list of products that have been sold the most",
    "What is the average price of products sold?"
]
};

const CasaAISuggestions = () => {
  const { addUserMessage, activeSession, module } = useCasaAI();

  const handleAddSuggestion = (suggestion: string) => {
    if (!activeSession) return;

    addUserMessage(activeSession.id, suggestion);
  };

  return (
    <Box id={styles.casaAISuggestions}>
      {suggestions[module].map((suggestion) => (
        <Card
          key={suggestion}
          onClick={handleAddSuggestion.bind(null, suggestion)}
        >
          <CardContent>{suggestion}</CardContent>
        </Card>
      ))}
    </Box>
  );
};
export default CasaAISuggestions;
