import { createSvgIcon } from '@mui/material';

const CommerceConnect = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M27.9559 17.9598L25.4209 14.7748L25.3859 14.7198C25.3162 14.637 25.2214 14.5791 25.1159 14.5548L19.0009 12.8098L19.3559 13.9098L23.5409 15.1098L16.0859 18.1498C16.0859 18.1498 8.64594 15.0948 8.64594 15.0998L12.6459 13.9098L13.0009 12.7998L7.06094 14.5548C6.94812 14.5992 6.84957 14.6735 6.77594 14.7698L4.05594 17.8198C4.00795 17.8794 3.97427 17.9492 3.95749 18.0238C3.94071 18.0984 3.94128 18.1759 3.95915 18.2503C3.97702 18.3247 4.01171 18.3939 4.06057 18.4528C4.10942 18.5117 4.17113 18.5585 4.24094 18.5898L6.71094 19.5898V25.1098C6.7126 25.2037 6.74068 25.2953 6.79197 25.374C6.84326 25.4527 6.91568 25.5154 7.00094 25.5548L15.8859 29.4548C15.9484 29.4846 16.0167 29.5 16.0859 29.5C16.1551 29.5 16.2235 29.4846 16.2859 29.4548L25.2009 25.5548C25.2862 25.5154 25.3586 25.4527 25.4099 25.374C25.4612 25.2953 25.4893 25.2037 25.4909 25.1098V19.5698L27.7509 18.7148C27.82 18.685 27.8814 18.6401 27.9307 18.5833C27.9799 18.5265 28.0157 18.4593 28.0354 18.3867C28.0551 18.3142 28.0582 18.2381 28.0444 18.1642C28.0307 18.0903 28.0004 18.0204 27.9559 17.9598ZM5.23594 17.9598L7.32594 15.6098L15.2359 18.8548L13.0009 21.0748L5.23594 17.9598ZM18.8459 21.0498L16.9009 18.8648L24.8509 15.6048L26.7909 18.0448L18.8459 21.0498Z'
      fill='currentColor'
    />
    <path
      d='M11.0795 9.83006C11.0505 9.92719 11.0517 10.0309 11.0829 10.1273C11.1142 10.2237 11.174 10.3084 11.2545 10.3701L13.7295 12.1651L13.5795 12.6351C13.3795 13.2351 12.9795 14.4701 12.7845 15.0751C12.7635 15.17 12.7706 15.2689 12.805 15.3598C12.8393 15.4508 12.8994 15.5297 12.978 15.587C13.0565 15.6443 13.15 15.6775 13.2471 15.6824C13.3441 15.6874 13.4405 15.664 13.5245 15.6151L15.9995 13.8151L18.4995 15.6151C18.5835 15.664 18.6799 15.6874 18.777 15.6824C18.874 15.6775 18.9675 15.6443 19.0461 15.587C19.1246 15.5297 19.1847 15.4508 19.219 15.3598C19.2534 15.2689 19.2605 15.17 19.2395 15.0751C19.0395 14.4751 18.6545 13.2351 18.4495 12.6501L18.2945 12.1501L20.7945 10.3551C20.8639 10.2863 20.9117 10.1988 20.9319 10.1032C20.9521 10.0077 20.9439 9.9083 20.9082 9.81737C20.8726 9.72643 20.8111 9.64792 20.7314 9.59153C20.6516 9.53514 20.5571 9.50334 20.4595 9.50006H17.4045L16.4595 6.59506C16.4211 6.50486 16.357 6.42795 16.2752 6.37389C16.1934 6.31984 16.0975 6.29102 15.9995 6.29102C15.9015 6.29102 15.8056 6.31984 15.7238 6.37389C15.642 6.42795 15.5779 6.50486 15.5395 6.59506L14.5945 9.50006H11.5395C11.4385 9.50213 11.3404 9.53477 11.2583 9.59368C11.1762 9.65259 11.1138 9.73501 11.0795 9.83006Z'
      fill='currentColor'
    />
    <path
      d='M22.1402 12.1848C22.2329 12.272 22.3554 12.3206 22.4827 12.3206C22.6099 12.3206 22.7324 12.272 22.8252 12.1848L26.8252 8.18482C26.8897 8.08763 26.9182 7.97093 26.9058 7.85492C26.8934 7.73891 26.8408 7.6309 26.7571 7.5496C26.6734 7.4683 26.5639 7.41881 26.4476 7.40972C26.3313 7.40063 26.2155 7.43251 26.1202 7.49982L22.1202 11.4998C22.0356 11.5951 21.9906 11.719 21.9944 11.8463C21.9981 11.9736 22.0502 12.0947 22.1402 12.1848Z'
      fill='currentColor'
    />
    <path
      d='M20.5453 7.40024C20.673 7.39711 20.7947 7.34522 20.8853 7.25524L24.8203 3.32524C24.8871 3.28726 24.9442 3.23441 24.9872 3.17079C25.0303 3.10717 25.0581 3.03449 25.0685 2.95839C25.0789 2.88229 25.0716 2.80481 25.0473 2.73197C25.0229 2.65913 24.9821 2.59288 24.9279 2.53837C24.8738 2.48386 24.8079 2.44255 24.7352 2.41764C24.6626 2.39273 24.5851 2.3849 24.509 2.39476C24.4328 2.40461 24.3599 2.43188 24.296 2.47445C24.232 2.51702 24.1788 2.57375 24.1403 2.64024L20.2053 6.57523C20.1449 6.64526 20.1052 6.73065 20.0904 6.82192C20.0756 6.91319 20.0865 7.00677 20.1217 7.09225C20.1569 7.17773 20.2152 7.25177 20.29 7.30614C20.3647 7.36051 20.4531 7.39308 20.5453 7.40024Z'
      fill='currentColor'
    />
    <path
      d='M9.27984 12.2899C9.37484 12.3428 9.48446 12.3632 9.59211 12.3482C9.69977 12.3331 9.79958 12.2834 9.87644 12.2065C9.95331 12.1297 10.003 12.0298 10.0181 11.9222C10.0331 11.8145 10.0127 11.7049 9.95984 11.6099L5.95984 7.60992C5.86485 7.55708 5.75523 7.53664 5.64757 7.55169C5.53992 7.56674 5.44011 7.61646 5.36324 7.69332C5.28638 7.77018 5.23666 7.87 5.22161 7.97765C5.20656 8.0853 5.22701 8.19493 5.27984 8.28992L9.27984 12.2899Z'
      fill='currentColor'
    />
    <path
      d='M11.2148 7.36555C11.3098 7.41838 11.4195 7.43883 11.5271 7.42378C11.6348 7.40873 11.7346 7.35901 11.8114 7.28215C11.8883 7.20529 11.938 7.10547 11.9531 6.99782C11.9681 6.89016 11.9477 6.78054 11.8948 6.68555L7.95984 2.75055C7.86485 2.69771 7.75523 2.67727 7.64757 2.69232C7.53992 2.70736 7.44011 2.75709 7.36324 2.83395C7.28638 2.91081 7.23666 3.01063 7.22161 3.11828C7.20656 3.22593 7.22701 3.33555 7.27984 3.43055L11.2148 7.36555Z'
      fill='currentColor'
    />
  </svg>,
  'CommerceConnect'
);

export default CommerceConnect;
