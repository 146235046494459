import {Box, Typography} from '@mui/material';
import {FC} from 'react';

import {ErrorEntity} from '../../../interfaces/MessageEntity';

const MessageEntityError: FC<ErrorEntity> = ({ value }) => {
  return (
    <Box>
      <Typography>{value}</Typography>
    </Box>
  );
};

export default MessageEntityError;
