import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const UserManagement = lazy(() => import("../components/UserManagement"));

const UserManagementRoute: RouteObject = {
  path: "/settings/user-management",
  element: <UserManagement />,
};

export default UserManagementRoute;
