import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import useCasaAI from '../../hooks/useCasaAI';
import styles from './CasaAIFooter.module.scss';

interface CasaAIForm {
  message: string;
}

const CasaAIFooter = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<CasaAIForm>({
    defaultValues: { message: '' },
  });
  const { addUserMessage, activeSession, currentConversation } = useCasaAI();

  const onSubmit = (data: CasaAIForm) => {
    if (
      !currentConversation ||
      currentConversation?.loading ||
      !activeSession?.id
    )
      return;

    addUserMessage(activeSession.id, data.message);
    reset();
  };

  useEffect(() => {
    reset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession]);

  return (
    <Box
      id={styles.casaAIFooter}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        fullWidth
        autoComplete='off'
        placeholder='Message Chan...'
        {...register('message', { required: 'Message is required' })}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                disabled={!isValid || !!currentConversation?.loading}
                edge='end'
                size='small'
                type='submit'
              >
                <SendIcon fontSize='inherit' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default CasaAIFooter;
