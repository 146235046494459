import { lighten } from '@mui/material';
import { Chart } from 'chart.js';

import chartPalette from './chartPalette';
import typography from './typography';

const initializeChartTheme = () => {
  Chart.defaults.font.family = typography.fontFamily;
  Chart.defaults.backgroundColor = (ctx) => {
    return lighten(chartPalette[ctx.datasetIndex], 0.65);
  };
  Chart.defaults.color = (ctx) => chartPalette[ctx.datasetIndex];

  Chart.defaults.datasets.line = {
    ...Chart.defaults.datasets.line,
    borderWidth: 1.5,
    tension: 0.3,
    pointStyle: 'circle',
    pointBackgroundColor: '#fff',
    pointRadius: 4,
    pointHoverRadius: 10,
    backgroundColor: (ctx) => lighten(chartPalette[ctx.datasetIndex], 0.65),
    pointBorderColor: (ctx) => chartPalette[ctx.datasetIndex],
    borderColor: chartPalette,
  };

  Chart.defaults.datasets.bar = {
    ...Chart.defaults.datasets.bar,
    borderRadius: 8,
    pointStyle: 'circle',
    backgroundColor: (ctx) => {
      return lighten(chartPalette[ctx.datasetIndex], 0.5);
    },
  };
};

export default initializeChartTheme;
