import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const ManageLead = lazy(() => import("../components/ManageLead"));

const ManageLeadRoute: RouteObject = {
  path: "/leads",
  element: <ManageLead />,
};

export default ManageLeadRoute;
