import {FC} from "react";
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {values} from "lodash";
import {beautifyHeader} from "../../../../utils/TextModifier";

interface TableProps {
  headers: string[]
  data: any[]
}
export const MessageEntityTable: FC<TableProps> = ({data, headers}) => {
  return (
    <Box>
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header}>{beautifyHeader(header)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.map((tableRow, index) => (
                  <TableRow key={index}>
                    {values(tableRow).map((value) => (
                      <TableCell>{String(value)}</TableCell>
                    ))}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}