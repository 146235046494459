import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CohortAnalysis = lazy(() => import('../components/CohortAnalysis'));

const cohortAnalysisRoute: RouteObject = {
  path: 'cohort-analysis',
  element: <CohortAnalysis />,
};

export default cohortAnalysisRoute;
