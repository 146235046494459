import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Survey = lazy(() => import('../components/Survey'));
const SurveyCreatorLazy = lazy(() => import('../components/SurveyCreator/SurveyCreator'));
const SurveyListingLazy = lazy(() => import('../components/SurveyListing/SurveyListing'));
const Form = lazy(() => import('../components/SurveyForm/SurveyForm'));
const Analytics = lazy(() => import('../components/SurveyAnalytics/SurveyAnalytics'));
const SurveyCreator: RouteObject = {
  path: 'survey',
  element: <Survey />,
  children:[
    {
      path: 'list',
      element: <SurveyListingLazy />,
    },
    {
      path: 'edit/:id' ,
      element: <SurveyCreatorLazy />,
    },
    {
      path: 'analytics/:id',
      element: <Analytics />,
    }
  ],
};

export const SurveyFormRoute: RouteObject = {
  path: 'customer/survey',
  element: <Survey/>,
  children: [
    {
      path: 'form/:surveyJwtToken' ,
      element: <Form />,
    }
  ]
}





export default SurveyCreator;
