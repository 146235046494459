import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import SearchHighlight from '../interfaces/SearchHighlight';

const searchHighlight = (text: string, query: string): SearchHighlight[] => {
  return parse(text, match(text, query, { insideWords: true }));
};

export default searchHighlight;
