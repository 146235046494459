import { createSvgIcon } from '@mui/material';

const ImportExport = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M11.9993 4L6.66602 9.32H10.666V18.6667H13.3327V9.32H17.3327L11.9993 4ZM21.3327 22.68V13.3333H18.666V22.68H14.666L19.9993 28L25.3327 22.68H21.3327Z'
      fill='currentColor'
    />
  </svg>,
  'ImportExport'
);

export default ImportExport;
