import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const e2eQaConfigs: EnvironmentConfig = {
  surveyJsKey: 'empty',
  dashboardClassic: 'https://dashboard.qa.casaretail.ai',
  ruleServerUrl: 'https://kapi.qa.casaretail.ai',
  apiServerUrl: 'https://kapi.qa.casaretail.ai', // TODO: Need to change
  casaWebAppUrl: 'https://web.qa.casaretail.ai',
  commerceConnect: 'http://commerce-connect.qa.casaretail.ai',
  ruleUIUrl: 'https://rules.qa.casaretail.ai',
  pwaUrl: 'qa.casarewards.com',
  dashboard: 'https://dashboard.casaqa.ajira.tech',
  keycloakConfig: {
    url: `https://qa.auth.ginesys.one/auth`,
    realm: `GinesysOne`,
    clientId: `GinesysOne`,
  },
  socialMedia: {
    facebook: {
      appId: '787341686188506',
    },
  },
};

export default e2eQaConfigs;
