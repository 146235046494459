import {Box, CircularProgress} from "@mui/material";
import useCasaAI from "../../hooks/useCasaAI";
import styles from './CasaAIHistory.module.scss'
import {GroupItem} from "./GroupItem";
import {useEffect, useState} from "react";
import {flatten, isUndefined, pick, values} from "lodash";
import {getLastNSessionByModule} from "../../services";
import useUser from "../../../../hooks/useUser";
import {CasaAiGroupedSession} from "../../interfaces/CasaAIGroupedSession";

export const CasaAIHistory= () => {
  const {
    module,
    activeSession,
  } = useCasaAI();
  const { selectedBusiness } = useUser();
  const [groupedSessions, setGroupedSessions] = useState<CasaAiGroupedSession>();

  useEffect(() => {
    const sessions = flatten(values(groupedSessions))
    for (var session of sessions) {
      if (session.id === activeSession?.id){
        return;
      }
    }
    setGroupedSessions(undefined);
    // eslint-disable-next-line
  },[module, activeSession])

  useEffect(() => {
    if (isUndefined(groupedSessions) && activeSession){
      getLastNSessionByModule(pick(selectedBusiness, ['id', 'name']), module)
        .then(res => {
          setGroupedSessions(res);
        })
    }
    // eslint-disable-next-line
  }, [activeSession, groupedSessions]);
  return <Box
    className={styles.history}
  >
    {groupedSessions ? Object.entries(groupedSessions).map(([groupName, sessions]) => (<GroupItem groupName={groupName} groupedSessions={sessions}/>)) : <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}><CircularProgress color='primary' size={24} /></Box>}
  </Box>
}