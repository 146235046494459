import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import CasaLogo from '../../logo/CasaLogo';
import styles from './PageLoader.module.scss';

const PageLoader = () => {
  return (
    <Box id={styles.pageLoader}>
      <CasaLogo width={500} />
      <CircularProgress size={16} color='secondary' />
    </Box>
  );
};

export default PageLoader;
