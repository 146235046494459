import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Search from '../../../../shared/icons/Search';
import InstantSearchDialog from '../InstantSearchDialog/InstantSearchDialog';
import styles from './InstantSearch.module.scss';
import { classnames } from '../../../../utils';

const InstantSearch = () => {
  const [isInstantSearchActive, setIsInstantSearchActive] = useState(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const clickListener = (event: KeyboardEvent) => {
      const { code, ctrlKey } = event;

      if (ctrlKey && code === 'KeyK') {
        event.preventDefault();
        event.stopImmediatePropagation();
        setIsInstantSearchActive(true);
      }
    };

    window.addEventListener('keydown', clickListener);
    return () => window.removeEventListener('keydown', clickListener);
  }, []);

  return (
    <>
      {isMobileView && (
        <IconButton
          onClick={setIsInstantSearchActive.bind(null, true)}
          color='primary'
        >
          <Search color='primary' fontSize='small' />
        </IconButton>
      )}
      {!isMobileView && (
        <Card
          component={Button}
          size='small'
          variant='outlined'
          id={styles.instantSearch}
          className={classnames({
            [styles.mobileSearch]: isMobileView,
          })}
          startIcon={<Search color='primary' fontSize='small' />}
          onClick={setIsInstantSearchActive.bind(null, true)}
          color='primary'
        >
          <Typography color='text.secondary' variant='body2'>
            Search...
          </Typography>
          <Box
            className={styles.instantSearchShortcut}
            sx={{ bgcolor: 'primary.main', color: 'white' }}
          >
            <Typography
              className={styles.instantSearchShortcutText}
              variant='caption'
            >
              Ctrl+K
            </Typography>
          </Box>
        </Card>
      )}
      {isInstantSearchActive && (
        <InstantSearchDialog
          onClose={setIsInstantSearchActive.bind(null, false)}
        />
      )}
    </>
  );
};

export default InstantSearch;
