import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const BillUploadContainer = lazy(
  () => import('../components/BillUploadContainer')
);

const billUploadRoute: RouteObject = {
  path: 'bill-upload',
  element: <BillUploadContainer />,
};

export default billUploadRoute;
