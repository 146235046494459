import { createSvgIcon } from '@mui/material';

const SocialMediaReward = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M5.9977 17.4092H2.57115C2.25117 17.4092 2 17.6604 2 17.9803V27.1177C2 27.4377 2.25117 27.6888 2.57115 27.6888H5.9977C6.31768 27.6888 6.56852 27.4377 6.56852 27.1177V17.9803C6.56885 17.6604 6.31768 17.4092 5.9977 17.4092Z'
      fill='currentColor'
    />
    <path
      d='M22.5192 18.4918C22.5192 17.5612 21.8802 16.6194 20.6599 16.6194H15.1272C15.9176 15.2055 16.15 13.2168 15.6006 11.7618C15.197 10.6916 14.4257 10.0671 13.4276 10.0036L13.4114 10.0023C12.762 9.96245 12.1997 10.449 12.1457 11.0975C12.0035 12.5407 11.3711 15.0932 10.4649 15.9995C9.70179 16.7626 9.04867 17.0822 7.96596 17.6115C7.80927 17.6882 7.63809 17.7719 7.45703 17.8621C7.46032 17.9012 7.4623 17.9404 7.4623 17.9806V27.0248C7.59299 27.0695 7.72203 27.114 7.84877 27.1574C9.63562 27.7734 11.1799 28.305 13.5402 28.305H18.0132C19.2339 28.305 19.8725 27.3629 19.8725 26.4326C19.8725 26.1564 19.8165 25.8795 19.7056 25.626C20.1121 25.553 20.468 25.3574 20.7317 25.0582C21.0309 24.7181 21.1958 24.2661 21.1958 23.7855C21.1958 23.5103 21.1399 23.2335 21.0293 22.9807C22.0093 22.8115 22.5195 21.9704 22.5195 21.1388C22.5195 20.6566 22.3477 20.171 22.0126 19.8155C22.347 19.4596 22.5192 18.9741 22.5192 18.4918Z'
      fill='currentColor'
    />
    <path
      d='M29.3027 7.55292C29.3027 11.5643 23.2792 14.8112 23.0227 14.9453C22.9551 14.9812 22.8795 15 22.8027 15C22.726 15 22.6504 14.9812 22.5828 14.9453C22.3263 14.8112 16.3027 11.5643 16.3027 7.55292C16.3038 6.61095 16.6833 5.70787 17.3578 5.0418C18.0324 4.37573 18.947 4.00106 19.9009 4C21.0994 4 22.1487 4.50887 22.8027 5.36902C23.4568 4.50887 24.5061 4 25.7045 4C26.6585 4.00106 27.5731 4.37573 28.2477 5.0418C28.9222 5.70787 29.3017 6.61095 29.3027 7.55292Z'
      fill='currentColor'
    />
  </svg>,
  'SocialMediaReward'
);

export default SocialMediaReward;
