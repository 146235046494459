import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const InvoiceCreatorComponent = lazy(() => import('../components'))

const InvoiceCreator: RouteObject = {
  path: 'invoice-creator',
  element: <InvoiceCreatorComponent />,
}

export default InvoiceCreator
