import axios from 'axios';

import config from '../config';
import User from '../interfaces/User';


const { apiServerUrl, ruleServerUrl } = config;

export const getUser = () => {
  return axios.get<User>(`${apiServerUrl}/users`).then(({ data }) => data);
};

export const getPosToken = (id: string) => {
  return axios.get(`${ruleServerUrl}/users/get-token/${id}`).then(({ data }) => data);
}