import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const BlacklistManagement = lazy(
  () => import("../components/BlacklistManagement")
);

const BlackList = lazy(() => import("../components/BlackList/BlackList"));

const EditBlacklistManagement = lazy(
  () => import("../components/EditBlacklist/EditBlacklist")
);

const BlacklistConfigRoute: RouteObject = {
  path: "/settings/blacklist-management",
  element: <BlacklistManagement />,
  children: [
    { index: true, element: <BlackList /> },
    { path: ":id", element: <EditBlacklistManagement /> },
  ],
};

export default BlacklistConfigRoute;
