import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const OneFlowOtpConfig = lazy(() => import('../components/OneFlowOtpConfig'));

const OneFlowOtpConfigRoute: RouteObject = {
  path: '/settings/modules/personify/one-flow',
  element: <OneFlowOtpConfig />,
};

export default OneFlowOtpConfigRoute;
