import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const LoyaltyOtpConfig = lazy(() => import('../components/LoyaltyOtpConfig'));

const LoyaltyOtpConfigRoute: RouteObject = {
  path: '/settings/modules/retensee/otp-config',
  element: <LoyaltyOtpConfig />,
};

export default LoyaltyOtpConfigRoute;
