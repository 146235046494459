import { useMemo, useState } from 'react';

import { shouldRenderCasaAiByRoute } from '../../../utils/shouldRenderCasaAi';
import { CasaAIProvider } from '../context/CasaAI';
import CasaAIDialog from './CasaAIDialog/CasaAIDialog';
import CasaAIFloatingButton from './CasaAIFloatingButton/CasaAIFloatingButton';
import useUser from '../../../hooks/useUser';
import { TenantConfigTypes } from '../../../shared/enums/TenantConfigs';
import { OnBoardingModules } from '../../../shared/enums/OnBoardingModules';
import Permissions from '../../../shared/enums/Permissions';

const doesHaveCasaAiModuleEnabled = (
  moduleConfig: Record<string, any> | null
) => {
  if (!moduleConfig) return false;
  return moduleConfig.modulesConfig[OnBoardingModules.Chan]?.enabled || false;
};

const CasaAI = () => {
  const [show, setShow] = useState(false);
  const { getTenantLevelConfig, userPermissions } = useUser();

  const _shouldRenderCasaAi = useMemo(() => {
    const modulesConfig = getTenantLevelConfig(
      TenantConfigTypes.OnboardingModules
    );
    const doesHaveChanPermission =
      userPermissions.includes(Permissions.COPY_WRITE) ||
      userPermissions.includes(Permissions.INSIGHTS);
    return (
      doesHaveChanPermission &&
      doesHaveCasaAiModuleEnabled(modulesConfig) &&
      shouldRenderCasaAiByRoute(window.location.pathname)
    );
    // eslint-disable-next-line
  }, []);

  if (!_shouldRenderCasaAi) return null;

  return (
    <CasaAIProvider>
      <CasaAIFloatingButton
        isActive={show}
        onClick={setShow.bind(null, true)}
      />
      <CasaAIDialog open={show} onClose={setShow.bind(null, false)} />
    </CasaAIProvider>
  );
};
export default CasaAI;
