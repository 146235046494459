const chartPalette: string[] = [
  '#F2755F',
  '#9CD866',
  '#4CBEAC',
  '#B48A2A',
  '#00836E',
  '#075064',
  '#C9472F',
  '#FFA156',
  '#0B85A7',
  '#F6C75F',
  '#6F4E7B',
  '#62B815',
  '#DD6200',
];

export default chartPalette;
