import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const RetenseeContainer = lazy(() => import("../components/Retensee"));

const RetenseeRoute: RouteObject = {
  path: "settings/modules/retensee",
  element: <RetenseeContainer />,
};

export default RetenseeRoute;
