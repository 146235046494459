import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import { FC, useState } from 'react';

const CopyButton: FC<IconButtonProps & { copyText: string }> = ({
  copyText,
  ...rest
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const Icon = isCopied ? DoneIcon : ContentCopyIcon;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1500);
      })
      .catch((error) => {
        console.error('Failed to copy:', error);
      });
  };

  return (
    <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
      <IconButton {...rest} onClick={handleCopy}>
        <Icon fontSize='inherit' />
      </IconButton>
    </Tooltip>
  );
};
export default CopyButton;
