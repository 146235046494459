import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const JourneyListContainer = lazy(
  () => import('../components/JourneyListContainer')
);
const JourneyList = lazy(() => import('../components/JourneyList/JourneyList'));
const JourneyDrafts = lazy(
  () => import('../components/JourneyDrafts/JourneyDrafts')
);
const JourneyCohortSelect = lazy(
  () => import('../components/JourneySelectCohort/JourneyCohortSelect')
);

const journeyListRoutes: RouteObject[] = [
  {
    path: 'journey/cohort',
    element: <JourneyCohortSelect />,
  },
  {
    path: 'journey',
    element: <JourneyListContainer />,
    children: [
      {
        index: true,
        element: <JourneyList />,
      },
      {
        path: 'drafts',
        element: <JourneyDrafts />,
      },
    ],
  },
];

export default journeyListRoutes;
