import {
  Color,
  PaletteColorOptions,
  PaletteOptions,
  TypeText,
} from '@mui/material';
import { common } from '@mui/material/colors';

import colors from '../styles/colors.scss';

const primary: PaletteColorOptions = {
  main: colors.primaryMain,
  light: colors.primaryLight,
  dark: colors.primaryDark,
  contrastText: colors.primaryContrastText,
};

const secondary: PaletteColorOptions = {
  main: colors.secondaryMain,
  light: colors.secondaryLight,
  dark: colors.secondaryDark,
  contrastText: colors.secondaryContrastText,
};

const success: PaletteColorOptions = {
  main: colors.successMain,
  light: colors.successLight,
  dark: colors.successDark,
  contrastText: colors.successContrastText,
};

const info: PaletteColorOptions = {
  main: colors.infoMain,
  light: colors.infoLight,
  dark: colors.infoDark,
  contrastText: colors.infoContrastText,
};

const warning: PaletteColorOptions = {
  main: colors.warningMain,
  light: colors.warningLight,
  dark: colors.warningDark,
  contrastText: colors.warningContrastText,
};

const error: PaletteColorOptions = {
  main: colors.errorMain,
  light: colors.errorLight,
  dark: colors.errorDark,
  contrastText: colors.errorContrastText,
};

const text: TypeText = {
  primary: colors.textPrimary,
  secondary: colors.textSecondary,
  disabled: colors.textDisabled,
};

const grey: Partial<Color> = {
  50: colors.grey50,
  100: colors.grey100,
  200: colors.grey200,
  300: colors.grey300,
  400: colors.grey400,
  500: colors.grey500,
  600: colors.grey600,
  700: colors.grey700,
  800: colors.grey800,
  900: colors.grey900,
  A100: colors.greyA100,
  A200: colors.greyA200,
  A400: colors.greyA400,
  A700: colors.greyA700,
};

const palette: PaletteOptions = {
  primary,
  secondary,
  success,
  info,
  warning,
  error,
  text,
  grey,
  divider: grey[300],
  background: {
    default: common.white,
    paper: common.white,
  },
};

export default palette;
