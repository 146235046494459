import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const RoleManagement = lazy(() => import('../components/RoleManagement'));

const roleConfigRoute: RouteObject = {
  path: '/settings/role-management',
  element: <RoleManagement />,
};

export default roleConfigRoute;
