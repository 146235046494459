import { createSvgIcon } from '@mui/material';

const Intelliclose = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='32'
    viewBox='0 0 26 32'
    fill='none'
  >
    <path
      d='M21.7996 18.4638C19.9756 18.8798 16.7116 19.2958 15.5596 18.9438C15.0796 18.7198 13.8956 17.4078 13.2556 16.7038C12.6476 16.0318 12.0716 15.4238 11.5276 14.9118C10.5996 13.9838 9.86361 13.3438 8.51961 12.9918C6.88761 12.5758 2.79161 12.0958 1.63961 14.8158C0.423609 17.6638 0.615609 25.0878 0.679609 30.3678C0.679609 30.8158 0.967609 31.1998 1.44761 31.1998C2.56761 31.1998 9.67161 31.1998 10.1836 31.1998C10.7276 31.1998 10.9196 30.9118 10.9516 30.4958C10.9836 30.2078 11.2716 25.1838 11.4636 21.5998C12.2956 22.3998 13.1276 23.0718 14.0556 23.3598C14.9196 23.6478 15.9436 23.7438 16.9996 23.7438C19.4956 23.7438 22.1196 23.1998 22.7916 23.0398C24.0396 22.7518 24.8396 21.5038 24.5516 20.2558C24.2956 18.9758 23.0476 18.1758 21.7996 18.4638Z'
      fill='currentColor'
    />
    <path
      d='M6.69677 11.4238C9.6305 11.4238 12.0088 9.04554 12.0088 6.1118C12.0088 3.17807 9.6305 0.799805 6.69677 0.799805C3.76303 0.799805 1.38477 3.17807 1.38477 6.1118C1.38477 9.04554 3.76303 11.4238 6.69677 11.4238Z'
      fill='currentColor'
    />
    <path
      d='M24.5194 8.44789L22.3434 8.25589C21.9914 8.22389 21.7034 7.99989 21.5754 7.67989L20.7434 5.66389C20.4234 4.89589 19.3674 4.89589 19.0474 5.66389L18.2154 7.67989C18.0874 7.99989 17.7994 8.22389 17.4474 8.25589L15.2394 8.44789C14.4394 8.51189 14.0874 9.53589 14.7274 10.0479L16.3914 11.4559C16.6474 11.6799 16.7754 12.0319 16.6794 12.3519L16.1674 14.4639C15.9754 15.2639 16.8394 15.8719 17.5434 15.4559L19.3994 14.3039C19.6874 14.1119 20.0714 14.1119 20.3594 14.3039L22.2154 15.4559C22.9194 15.8719 23.7834 15.2639 23.5914 14.4639L23.0794 12.3519C23.0154 12.0319 23.1114 11.6799 23.3674 11.4559L25.0314 10.0479C25.6714 9.53589 25.3194 8.51189 24.5194 8.44789Z'
      fill='currentColor'
    />
  </svg>,
  'Intelliclose'
);

export default Intelliclose;
