import { BreakpointsOptions } from '@mui/material';
import breakpointsStyles from '../styles/breakpoints.scss';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: +breakpointsStyles.xs,
    sm: +breakpointsStyles.sm,
    md: +breakpointsStyles.md,
    lg: +breakpointsStyles.lg,
    xl: +breakpointsStyles.xl,
  },
};

export default breakpoints;
