import { Dictionary } from 'lodash'
import { BILL_TOKEN_FIELD } from '../features/InvoiceGenerator/constants'

const doesRouteTokenAuthenticated = () => {
  const currentUrl = new URL(window.location.href)
  const jwtBasedAuthUrlPatterns = [
    /^\/customer\/survey\/form\/[a-zA-Z0-9_.-]+$/
  ]
  for (let pattern of jwtBasedAuthUrlPatterns) {
    if (pattern.test(currentUrl.pathname)){
      return true;
    }
  }
  const urlAuthMap: Dictionary<() => boolean> = {
    '/invoice': () => currentUrl.searchParams.has(BILL_TOKEN_FIELD)
  }

  return urlAuthMap[currentUrl.pathname]
    ? urlAuthMap[currentUrl.pathname]()
    : false
}

export { doesRouteTokenAuthenticated }
