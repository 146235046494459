import {Box, Container} from '@mui/material';
import React, {Suspense} from 'react';
import {Outlet, ScrollRestoration} from 'react-router-dom';

import PageLoader from '../../../shared/components/PageLoader/PageLoader';
import styles from './Dashboard.module.scss';

const Dashboard = () => {

  return (
    <Box id={styles.dashboard}>
      <Box >
        <Container component='main' className={styles.container}>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
      <ScrollRestoration />
    </Box>
  );
};

export default Dashboard;
