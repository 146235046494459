import {Avatar, Box, Typography} from '@mui/material';
import {FC, useEffect, useState} from 'react';

import CasaAI from '../../../../assets/images/CasaAI.jpg';
import useUser from '../../../../hooks/useUser';
import DotLoader from '../../../../shared/components/DotLoader/DotLoader';
import useCasaAI from '../../hooks/useCasaAI';
import MessageUser from '../../interfaces/MessageUser';
import MessageType from '../../utils/Message';
import MessageEntity from '../MessageEntity/MessageEntity';
import styles from './Message.module.scss';
import {getFeedbackForMessage, saveFeedbackForMessage} from "../../services";
import {isUndefined, pick} from "lodash";
import {CasaAIFeedback, FeedbackTypes} from "../../interfaces/CasaAIFeedback";
import Feedback from "../Feedback/Feedback";

const Message: FC<
  MessageType & { loading?: boolean; showOptions: boolean }
> = ({ type: user, content, loading = false, showOptions }) => {
  const { aiName } = useCasaAI();
  const { currentUser, selectedBusiness } = useUser();
  const [existingFeedback, setExistingFeedback] = useState<CasaAIFeedback | undefined>()
  const [isFeedbackLoaded, setIsFeedbackLoaded] = useState(false)

  useEffect(() => {
    if (content.id === undefined || content.id === '' || isFeedbackLoaded || !showOptions){
      return;
    }
    getFeedbackForMessage(pick(selectedBusiness, ['id', 'name']), content.id)
      .then(feedbackResponse => {
        if (isUndefined(feedbackResponse) || feedbackResponse == null){
          return;
        }
        if (feedbackResponse.status !== 'success') {
          return;
        } else {
          setExistingFeedback(feedbackResponse.data as CasaAIFeedback);
        }
        setIsFeedbackLoaded(true);
      })
    // eslint-disable-next-line
  }, [content.id, selectedBusiness, isFeedbackLoaded]);
  const isFeedbackAvailable = () => {
      if (!isFeedbackLoaded){
          return false;
      }
      if (content.id === undefined || content.id === ''){
        return false;
      }
      if (user === MessageUser.AI && showOptions){
          return true;
      }
      return false;
  }

  const onSubmitFeedback = async (feedbackType: FeedbackTypes) => {
      if (content.id !== undefined){
          const resp = await saveFeedbackForMessage(pick(selectedBusiness, ['id', 'name']), content.id, feedbackType)
          if (resp.status === 'success'){
              return {status: 'success', message: 'Thanks for your feedback!'}
          }
      }
      return {status: 'error', message:'Something went wrong'};
  }

  return (
    <Box className={styles.message} id={content.id}>
      <Box>
        {user === MessageUser.HUMAN ? (
          <Avatar>{currentUser?.email.slice(0, 1).toUpperCase()}</Avatar>
        ) : (
          <Avatar src={CasaAI} />
        )}
      </Box>
      <Box>
        <Box>
          <Typography variant='body1' fontWeight={600}>
            {user === MessageUser.HUMAN ? 'You' : aiName}
          </Typography>
        </Box>
        {loading ? (
          <Box className={styles.loader}>
            <DotLoader />
          </Box>
        ) : (
          <Box className={styles.messageEntity}>
            {content.message.map((entity) => (
              <MessageEntity key={entity.value.toString()} {...entity} showOptions={showOptions} />
            ))}
              {isFeedbackAvailable() && <Feedback onClick={onSubmitFeedback} feedback={existingFeedback}/> }
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Message;
