import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const FestivalAnalysis = lazy(() => import("../components/FestivalAnalysis"));

const customerAnalysis: RouteObject = {
  path: "/customer-cube/festival-analysis",
  element: <FestivalAnalysis />,
};

export default customerAnalysis;