import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import ErrorBoundary from '../../../error/ErrorBoundary/ErrorBoundary'

const InvoiceComponent = lazy(() => import('../components'))

const Invoice: RouteObject = {
  path: '/invoice',
  element: <InvoiceComponent />,
  errorElement: <ErrorBoundary />,
}

export default Invoice
