export type CasaAIFeedback = {
    id: number;
    messageId: string;
    additionalFeedback: string;
    feedbackType: string;
}

export type CasaAIFeedbackResponse = {
    status: string;
    data: string | CasaAIFeedback
}

export enum FeedbackTypes {
    POSITIVE = "positive",
    NEGATIVE = "negative"
}