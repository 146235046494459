import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const CommerceConnectSettings = lazy(() => import('../components'))


const ProductSyncConfig = lazy(
  () => import('../components/Tabs/ProductSyncConfig/index')
);
const CommerceConnectSettingsRoute: RouteObject = {
  path: 'settings/modules/commerce-connect',
  element: <CommerceConnectSettings />,
  children: [
    {
      index: true,
      element: <ProductSyncConfig />,
    }
  ],
};

export default CommerceConnectSettingsRoute
