import AbcIcon from '@mui/icons-material/Abc';
import HtmlIcon from '@mui/icons-material/Html';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { pick } from 'lodash';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';

import useUser from '../../../../../hooks/useUser';
import CopyButton from '../../../../../shared/components/CopyButton/CopyButton';
import SaveIconButton from '../../../../../shared/components/SaveIconButton/SaveIconButton';
import formatNumber from '../../../../../utils/formatNumber';
import sanitizeHTML from '../../../../../utils/sanitizeHTML';
import { MessageTemplate } from '../../../interfaces/MessageEntity';
import { saveMessageTemplate } from '../../../services';
import styles from '../MessageEntity.module.scss';
import MessageOptions from '../MessageOptions';

const VARIABLES_REGEX = /##\w+##/g;

const MessageTemplateEntity: FC<MessageTemplate & { showOptions: boolean }> = ({
  value: { messageType, name, content },
  options = [],
  showOptions,
}) => {
  const { selectedBusiness } = useUser();
  const [showHTML, setShowHTML] = useState(false); // Info: This is only used when messageType is 'Email'
  const [savingTemplate, setSavingTemplate] = useState(false);

  const sanitizedTemplate = sanitizeHTML(content);
  const smsCharacterCount = sanitizedTemplate.replace(
    VARIABLES_REGEX,
    ''
  ).length;

  const handleToggleView = () => {
    setShowHTML((showHTML) => !showHTML);
  };

  const handleSaveTemplate = () => {
    setSavingTemplate(true);
    saveMessageTemplate(pick(selectedBusiness, ['id', 'name']), {
      channel: messageType,
      content: content,
      name: name,
    })
      .then(() => toast.success('Template Saved Successfully'))
      .catch((error) => toast.error(error.message))
      .finally(() => setSavingTemplate(false));
  };

  return (
    <>
      <Card id={styles.messageTemplateEntity}>
        <CardContent className={styles.messageTemplateEntityContent}>
          {!showHTML && messageType === 'email' ? (
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitizedTemplate }}
            />
          ) : (
            <Typography>{sanitizedTemplate}</Typography>
          )}
        </CardContent>
        <CardActions className={styles.messageTemplateEntityAction}>
          <Box>
            <SaveIconButton
              loading={savingTemplate}
              onSave={handleSaveTemplate}
            />
            <CopyButton size='small' copyText={content} color='secondary' />
            {messageType === 'email' && (
              <Tooltip title={`View ${showHTML ? 'Text' : 'HTML'}`}>
                <IconButton size='small' onClick={handleToggleView}>
                  {showHTML ? <AbcIcon /> : <HtmlIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Tooltip title="Character's count excluding variables">
            <Typography variant='caption' color='secondary'>
              {formatNumber(smsCharacterCount)} Characters
            </Typography>
          </Tooltip>
        </CardActions>
      </Card>
      {showOptions && !!options.length && <MessageOptions options={options} />}
    </>
  );
};

export default MessageTemplateEntity;
