import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const LeadDashboardContainer = lazy(() => import("../components/LeadDashboard"));

const LeadDashboardRoute: RouteObject = {
  path: "/lead-dashboard",
  element: <LeadDashboardContainer />,
};

export default LeadDashboardRoute;
