import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CampaignContainer = lazy(
  () => import('../components/CampaignContainer')
);

const campaignRoute: RouteObject = {
  path: 'campaign/create',
  element: <CampaignContainer />,
};

export default campaignRoute;
