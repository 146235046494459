import { createSvgIcon } from '@mui/material';

const Integrations = createSvgIcon(
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.1785 22.5H12V20.3573C12.0007 20.1592 11.9612 19.9629 11.8839 19.7805C11.8067 19.598 11.6932 19.4331 11.5505 19.2956C11.191 18.9412 10.6678 18.742 10.1175 18.75C9.62799 18.7546 9.15576 18.9317 8.78393 19.2501C8.54018 19.4658 8.25002 19.8389 8.25002 20.3948V22.5H4.10721C3.41597 22.4992 2.75325 22.2243 2.26447 21.7355C1.77569 21.2468 1.50076 20.584 1.50002 19.8928V15.75H3.64268C4.07205 15.75 4.48971 15.5662 4.81877 15.2344C5.00184 15.0498 5.14661 14.8309 5.24476 14.5902C5.3429 14.3495 5.39248 14.0918 5.39064 13.8319C5.37658 12.8386 4.55908 12 3.60518 12H1.50002V7.81217C1.49867 7.47087 1.56665 7.13284 1.69984 6.8186C1.83303 6.50435 2.02866 6.22043 2.27486 5.98405C2.76655 5.50978 3.42407 5.24637 4.10721 5.24998H7.07158V4.57123C7.07158 4.16331 7.15283 3.75948 7.31059 3.3833C7.46834 3.00712 7.69945 2.66612 7.99041 2.38022C8.28137 2.09432 8.62637 1.86924 9.00526 1.71811C9.38415 1.56698 9.78935 1.49283 10.1972 1.49998C11.8608 1.52905 13.2141 2.92405 13.2141 4.6092V5.24998H16.1785C16.5172 5.24635 16.8532 5.31038 17.1668 5.43832C17.4805 5.56627 17.7654 5.75555 18.0049 5.99507C18.2445 6.23459 18.4337 6.51952 18.5617 6.83316C18.6896 7.1468 18.7537 7.48283 18.75 7.82155V10.7859H19.3903C21.105 10.7859 22.5 12.1453 22.5 13.8159C22.5 15.532 21.1224 16.9284 19.4288 16.9284H18.75V19.8928C18.75 21.3305 17.5964 22.5 16.1785 22.5Z'
      fill='currentColor'
    />
  </svg>,
  'Integrations'
);

export default Integrations;
