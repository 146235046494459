import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { NavigationLinkProvider } from './contexts/NavigationLink';
import routes from './routes';

const router = createBrowserRouter(routes);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <NavigationLinkProvider>
        <RouterProvider router={router} />
      </NavigationLinkProvider>
    </LocalizationProvider>
  );
}

export default App;
