import { createSvgIcon } from '@mui/material';

const CampaignDelight = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M15.2737 9.31004C15.2737 6.84892 13.2715 4.84668 10.8107 4.84668C8.3499 4.84668 6.34766 6.84892 6.34766 9.31004C6.34766 11.7708 8.3499 13.7731 10.8107 13.7731C13.2715 13.7731 15.2737 11.7708 15.2737 9.31004Z'
      fill='currentColor'
    />
    <path
      d='M23.2914 7.95611L23.3346 7.59067C23.6389 5.02395 22.2664 3.44571 22.2079 3.37979L21.9682 3.11035L15.8418 6.02427L16.6824 8.91675L23.2914 7.95611ZM21.6972 4.30235C21.9912 4.75803 22.4818 5.73691 22.412 7.11387L17.3714 7.84667L16.9916 6.54075L21.6972 4.30235Z'
      fill='currentColor'
    />
    <path
      d='M23.1833 4.05599C23.2611 4.05599 23.3398 4.03711 23.4131 3.99711L25.9609 2.60735C26.1936 2.48031 26.2793 2.18879 26.1523 1.95615C26.0256 1.72351 25.7344 1.63807 25.5011 1.76447L22.9533 3.15423C22.7206 3.28127 22.6349 3.57279 22.7619 3.80543C22.8486 3.96543 23.0134 4.05599 23.1833 4.05599Z'
      fill='currentColor'
    />
    <path
      d='M28.7948 3.69087C28.7269 3.43487 28.4636 3.28287 28.2079 3.34975L24.7596 4.26335C24.5033 4.33119 24.3506 4.59423 24.4185 4.85023C24.4757 5.06527 24.6697 5.20735 24.8821 5.20735C24.9228 5.20735 24.9644 5.20191 25.0053 5.19135L28.4537 4.27775C28.71 4.20991 28.8626 3.94687 28.7948 3.69087Z'
      fill='currentColor'
    />
    <path
      d='M27.8973 6.14265L24.2595 5.85689C23.9994 5.83673 23.7642 6.03353 23.7437 6.29785C23.7229 6.56217 23.9203 6.79321 24.1847 6.81401L27.8224 7.09977C27.8352 7.10073 27.8477 7.10105 27.8605 7.10105C28.1082 7.10105 28.3187 6.91001 28.3383 6.65849C28.3591 6.39449 28.1616 6.16345 27.8973 6.14265Z'
      fill='currentColor'
    />
    <path
      d='M26.8648 7.78665C26.7266 7.69833 26.5531 7.68649 26.4047 7.75529L7.9877 16.2647C7.81778 16.3434 7.70898 16.5134 7.70898 16.7006V29.6353C7.70898 29.9002 7.9237 30.1153 8.18898 30.1153H14.7848C15.0139 30.1153 15.2114 29.953 15.2559 29.7284L17.5298 18.2193L26.869 12.1089C27.0047 12.0202 27.0863 11.8692 27.0863 11.7073V8.19113C27.0863 8.02729 27.0027 7.87497 26.8648 7.78665Z'
      fill='currentColor'
    />
  </svg>,
  'CampaignDelight'
);

export default CampaignDelight;
