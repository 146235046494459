import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Integrations = lazy(() => import('../components/Integrations'));

const SocialMediaIntegration = lazy(
  () => import('../../SocialMediaIntegration/components/SocialMediaIntegration')
);

const VoiceCallIntegrations = lazy(
  () => import('../../VoiceCallIntegrations/components/VoiceCallIntegrations')
);

const Channels = lazy(
  () => import('../../Channels/components/Channels')
);

const integrationRoute: RouteObject = {
  path: '/settings/integrations',
  element: <Integrations />,
  children: [
    {
      path: 'channel',
      element: <Channels />,
    },
    {
      path: 'voice-call',
      element: <VoiceCallIntegrations />,
    },
    {
      path: 'social-media',
      element: <SocialMediaIntegration />,
    },
  ],
};

export default integrationRoute;
