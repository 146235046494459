import {Alert, Box, CircularProgress} from '@mui/material';
import {FC, useState} from 'react';
import styles from './Conversation.module.scss';
import CasaAIConversation from '../../interfaces/CasaAIConversation';
import MessageUser from '../../interfaces/MessageUser';
import Message from '../Message/Message';
import CasaAISession from "../../interfaces/CasaAiSession";

interface ConversationProps {
  loadMessagesBySessionAndPage: (sessionId: string, page: number) => Promise<void>;
  activeSession: CasaAISession | null;
}
const Conversation: FC<CasaAIConversation & ConversationProps> = ({
    messages,
    loading,
    error,
    totalPages,
    currentPage ,
    loadMessagesBySessionAndPage,
    activeSession
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isMessageLoadAvailable = () => {
    if (currentPage >= totalPages) {
      return false;
    }
    return true;
  }
  const loadMoreMessages = () => {
    if (activeSession === null || !isMessageLoadAvailable()){
      return;
    }
    setIsLoading(true);
    loadMessagesBySessionAndPage(activeSession?.id, currentPage+1)
      .then(() => {
        setIsLoading(false);
      })
  };

  return (
    <Box id={styles.conversation}>
      {isMessageLoadAvailable() &&
        (
          <Box className={styles.loadMessagesContainer}>
            {!isLoading && <Box className={styles.loadMoreButton} onClick={loadMoreMessages}>{"load more messages"}</Box>}
            {isLoading && <CircularProgress color='primary' size={24} />}
          </Box>
        )
      }
      {messages.map((message, index) => (
        <Message
          key={`${message.type}-${index}`}
          {...message}
          showOptions={index + 1 === messages.length}
        />
      ))}
      {loading && !error && (
        <Message type={MessageUser.AI} loading content={{id: '', message: []}} showOptions />
      )}
      {error && !loading && <Alert severity='error'>{error}</Alert>}
    </Box>
  );
};
export default Conversation;
