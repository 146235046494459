import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const JourneyBuilder = lazy(() => import('../components/JourneyBuilder'));
const JourneyBuilderView = lazy(
  () => import('../components/JourneyBuilderView/JourneyBuilderView')
);
const JourneyBuilderEdit = lazy(
  () => import('../components/JourneyBuilderEdit/JourneyBuilderEdit')
);

const journeyBuilderRoute: RouteObject = {
  path: '/journey/:id',
  element: <JourneyBuilder />,
  children: [
    {
      index: true,
      element: <JourneyBuilderView />,
    },
    {
      path: 'edit',
      element: <JourneyBuilderEdit />,
    },
  ],
};

export default journeyBuilderRoute;
