import { cloneDeep, concat, get, pick } from 'lodash';
import { createContext, FC, PropsWithChildren, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import useUser from '../../../hooks/useUser';
import { deserialize, serialize } from '../../../utils/serialization';
import CasaAIContextType from '../interfaces/CasaAIContext';
import CasaAIConversation from '../interfaces/CasaAIConversation';
import CasaAISession from '../interfaces/CasaAiSession';
import { MessageEntityType } from '../interfaces/MessageEntity';
import MessageUser from '../interfaces/MessageUser';
import {
  createNewSession,
  getCasaAIReply,
  getLastActiveOrNewSession,
  getMessageHistoryBySession,
} from '../services';
import Message from '../utils/Message';
import CasaAIModule from '../interfaces/CasaAIModule';
import Permissions from '../../../shared/enums/Permissions';

const AI_NAME = 'Chan';

const CasaAIContext = createContext<CasaAIContextType>({
  currentConversation: null,
  module: CasaAIModule.GENERATIVE,
  setModule() {},
  sessions: [],
  setSessions() {},
  setActiveSessionBySession() {},
  activeSession: null,
  isCreatingSession: false,
  aiName: AI_NAME,
  createSessionError: null,
  createSession() {},
  initializeSession() {},
  deleteSession(session) {},
  setActiveSession(id) {},
  addUserMessage(sessionId, message, isCustomized) {},
  loadMessagesBySessionAndPage(sessionId, page) {
    return new Promise((res) => {
      res();
    });
  },
});

export const CasaAIProvider: FC<PropsWithChildren> = ({ children }) => {
  const { selectedBusiness, userPermissions } = useUser();

  const [conversations, setConversations] = useState<
    Record<CasaAISession['id'], CasaAIConversation>
  >({});
  const [module, setModule] = useState<CasaAIModule>(
    userPermissions.includes(Permissions.INSIGHTS)
      ? CasaAIModule.GENERATIVE
      : CasaAIModule.COPY_WRITE
  );
  const [sessions, setSessions] = useState<CasaAISession[]>([]);
  const [activeSession, setActiveSession] = useState<CasaAISession | null>(
    null
  );
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [createSessionError, setCreateSessionError] = useState<string | null>(
    null
  );

  const __currentConversations = activeSession?.id
    ? serialize(conversations[activeSession.id])
    : null;

  const currentConversation: CasaAIConversation | null = useMemo(() => {
    return __currentConversations
      ? deserialize<CasaAIConversation>(__currentConversations)
      : null;
  }, [__currentConversations]);

  const loadMessagesBySessionAndPage = (sessionId: string, page: number) => {
    const business = pick(selectedBusiness, ['id', 'name']);
    return getMessageHistoryBySession(business, module, sessionId, page).then(
      (messageHistory) => {
        const oldConversation = conversations[sessionId];
        const oldMessages = get(oldConversation, 'messages', []);
        setConversations((conversations) => ({
          ...conversations,
          [sessionId]: {
            error: null,
            loading: false,
            messages: concat(messageHistory.messages, oldMessages),
            totalPages: messageHistory.totalPages,
            currentPage: messageHistory.currentPage,
            totalMessages: messageHistory.totalMessages,
          },
        }));
      }
    );
  };
  const handleFetchLastActiveSession = () => {
    setIsCreatingSession(true);
    setCreateSessionError(null);
    const business = pick(selectedBusiness, ['id', 'name']);
    getLastActiveOrNewSession(business, module)
      .then((newSession) => {
        loadMessagesBySessionAndPage(newSession.id, 1).then(() => {
          setSessions((sessions) =>
            sessions.concat({
              ...newSession,
            })
          );
          setActiveSession(newSession);
        });
      })
      .catch((error) => {
        setCreateSessionError(error.message);
        toast.error('Something went wrong with fetching session');
      })
      .finally(() => setIsCreatingSession(false));
  };

  const handleCreateSession = () => {
    setIsCreatingSession(true);
    setCreateSessionError(null);
    createNewSession(pick(selectedBusiness, ['id', 'name']), module)
      .then((newSession) => {
        setConversations((conversations) => ({
          ...conversations,
          [newSession.id]: {
            error: null,
            loading: false,
            messages: [],
            totalPages: 0,
            currentPage: 0,
            totalMessages: 0,
          },
        }));
        setSessions((sessions) =>
          sessions.concat({
            ...newSession,
          })
        );
        setActiveSession(newSession);
      })
      .catch((error) => {
        setCreateSessionError(error.message);
        toast.error('Something went wrong creating session');
      })
      .finally(() => setIsCreatingSession(false));
  };

  const handleDeleteSession = (session: CasaAISession) => {
    if (session.id === activeSession?.id) {
      const indexOfCurrentSession = sessions.findIndex(
        (currentSession) => session.id === currentSession.id
      );
      if (indexOfCurrentSession < 0) {
        return;
      }
    }
    setSessions((sessions) => sessions.filter(({ id }) => id !== session.id));
  };

  const handleActiveSession = (sessionId: string) => {
    console.log(sessions, sessionId);
    setActiveSession(() => sessions.find(({ id }) => id === sessionId) || null);
  };

  const handleAddMessage = (
    sessionId: CasaAISession['id'],
    message: Message
  ) => {
    return setConversations((conversations) => {
      const updatedConversations = cloneDeep(conversations);
      if (updatedConversations[sessionId]) {
        updatedConversations[sessionId].messages =
          updatedConversations[sessionId].messages.concat(message);
      }
      return updatedConversations;
    });
  };

  const setConversationLoading = (
    sessionId: CasaAISession['id'],
    loading: boolean
  ) => {
    return setConversations((conversations) => ({
      ...conversations,
      [sessionId]: {
        ...conversations[sessionId],
        loading,
      },
    }));
  };

  const setConversationError = (
    sessionId: CasaAISession['id'],
    error: CasaAIConversation['error']
  ) => {
    return setConversations((conversations) => ({
      ...conversations,
      [sessionId]: {
        ...conversations[sessionId],
        error,
      },
    }));
  };

  const addUserMessage = (
    sessionId: CasaAISession['id'],
    message: string,
    isCustomized: boolean = false
  ) => {
    const userMessage = new Message(MessageUser.HUMAN, {
      id: '',
      message: [{ type: MessageEntityType.TEXT, value: message }],
    });

    handleAddMessage(sessionId, userMessage);
    setConversationLoading(sessionId, true);
    setConversationError(sessionId, null);

    getCasaAIReply(
      message,
      pick(selectedBusiness, ['id', 'name']),
      sessionId,
      module,
      isCustomized
    )
      .then((aiMessage) => {
        handleAddMessage(sessionId, aiMessage);
        setConversationLoading(sessionId, false);
      })
      .catch((error) => setConversationError(sessionId, error.message))
      .finally(() => setConversationLoading(sessionId, false));
  };

  return (
    <CasaAIContext.Provider
      value={{
        currentConversation,
        module,
        setModule,
        sessions,
        setSessions,
        setActiveSessionBySession: setActiveSession,
        activeSession,
        createSessionError,
        isCreatingSession,
        aiName: AI_NAME,
        createSession: handleCreateSession,
        initializeSession: handleFetchLastActiveSession,
        deleteSession: handleDeleteSession,
        setActiveSession: handleActiveSession,
        addUserMessage,
        loadMessagesBySessionAndPage,
      }}
      children={children}
    />
  );
};
export default CasaAIContext;
