import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const CustomerAnalysis = lazy(() => import("../components/CustomerAnalysis"));

const customerAnalysis: RouteObject = {
  path: "/customer-cube/customer-analysis",
  element: <CustomerAnalysis />,
};

export default customerAnalysis;
