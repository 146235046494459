import { FC } from 'react';

import MessageEntityT, {
  MessageEntityType,
} from '../../interfaces/MessageEntity';
import MessageEntityText from './MessageEntityComponents/MessageEntityText';
import MessageTemplateEntity from './MessageEntityComponents/MessageTemplateEntity';
import {MessageEntityVisualization} from "./MessageEntityComponents/MessageEntityVisualization";
import MessageEntityError from "./MessageEntityComponents/MessageEntityError";

const MessageEntity: FC<MessageEntityT & { showOptions: boolean }> = (
  props
) => {
  switch (props.type) {
    case MessageEntityType.TEXT:
      return <MessageEntityText {...props} />;
    case MessageEntityType.ERROR:
      return <MessageEntityError {...props} />;
    case MessageEntityType.MESSAGE_TEMPLATE:
      return <MessageTemplateEntity {...props} />;
    case MessageEntityType.VISUALIZATION:
      return <MessageEntityVisualization {...props} />;
    default:
      return null;
  }
};

export default MessageEntity;
