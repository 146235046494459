import React, {FC, useEffect, useState} from "react";
import {Box} from "@mui/material";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import styles from './Feedback.module.scss';
import {CasaAIFeedback, FeedbackTypes} from "../../interfaces/CasaAIFeedback";
import {ThumbDownAlt, ThumbUpAlt} from "@mui/icons-material";
import { toast } from "react-hot-toast";

interface FeedbackProps  {
    onClick(event: string): Promise<any>;
    feedback: CasaAIFeedback | undefined;
}

const Feedback: FC<FeedbackProps> = ({onClick, feedback}) => {
    const [feedbackState, setFeedbackState] = useState<string>();

    useEffect(() => {
        if (feedback !== undefined) {
            setFeedbackState(feedback?.feedbackType);
        }
    }, [feedback]);

    const isFeedbackAvailable = () => {
        return feedbackState !== undefined;
    }

    const onSubmitFeedback = (feedbackType: FeedbackTypes) => {
        setFeedbackState(feedbackType);
        onClick(feedbackType).then(res => {
            if (res.status === 'error') {
                setFeedbackState(undefined);
                toast.error(res.message)
            } else {
                toast.success(res.message)
            }
        })
    }
    const getExistingFeedbackComponent = () => {
        switch (feedbackState){
            case FeedbackTypes.POSITIVE:
                return <ThumbUpAlt color={"secondary"} fontSize={"inherit"} className={styles.iconSize}/>
            case FeedbackTypes.NEGATIVE:
                return <ThumbDownAlt color={"secondary"} fontSize={"inherit"} className={styles.iconSize} />
        }
    }

    const getNewFeedbackComponent = () => {
        return <Box className={styles.newFeedback}>
            <ThumbUpAltOutlinedIcon
                color={"secondary"}
                fontSize={"inherit"}
                className={`${styles.iconSize} ${styles.clickable}`}
                onClick={() => onSubmitFeedback(FeedbackTypes.POSITIVE)}
            />
            <ThumbDownAltOutlinedIcon
                color={"secondary"}
                fontSize={"inherit"}
                className={`${styles.iconSize} ${styles.clickable}`}
                onClick={() => onSubmitFeedback(FeedbackTypes.NEGATIVE)}
            />
        </Box>
    }
    return (
        <Box className={styles.feedback}>
            {isFeedbackAvailable() ? getExistingFeedbackComponent() : getNewFeedbackComponent()}
        </Box>
    )
}

export default Feedback;