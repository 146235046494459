import { ButtonBase, ButtonBaseProps, Tooltip } from '@mui/material';
import { FC } from 'react';

import CasaAIIcon from '../../../../shared/icons/CasaAI';
import { classnames } from '../../../../utils';
import useCasaAI from '../../hooks/useCasaAI';
import styles from './CasaAIFloatingButton.module.scss';

const CasaAIFloatingButton: FC<ButtonBaseProps & { isActive: boolean }> = ({
  isActive,
  ...rest
}) => {
  const { aiName } = useCasaAI();

  return (
    <Tooltip title={`${aiName} AI`}>
      <ButtonBase
        {...rest}
        className={classnames({ [styles.active]: isActive })}
        disableRipple
        id={styles.casaAIFloatingButton}
      >
        <CasaAIIcon />
      </ButtonBase>
    </Tooltip>
  );
};
export default CasaAIFloatingButton;
