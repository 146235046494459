import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const SurveySettings = lazy(() => import('../components'))

const SurveySettingsRoute: RouteObject = {
  path: 'settings/modules/survey',
  element: <SurveySettings />,
}

export default SurveySettingsRoute
