import { Dialog, DialogContent, Divider } from '@mui/material';
import { FC, useCallback, useEffect, useLayoutEffect } from 'react';

import useCasaAI from '../../hooks/useCasaAI';
import CasaAIContent from '../CasaAIContent/CasaAIContent';
import CasaAIHeader from '../CasaAIHeader/CasaAIHeader';
import styles from './CasaAIDialog.module.scss';

interface CasaAIDialogProps {
  open: boolean;
  onClose: () => void;
}

const CasaAIDialog: FC<CasaAIDialogProps> = ({ open, onClose }) => {
  const {
    currentConversation,
    initializeSession,
    activeSession,
    module,
    setSessions,
    setActiveSessionBySession,
  } = useCasaAI();

  const reset = useCallback(() => {
    setSessions([]);
    setActiveSessionBySession(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  useLayoutEffect(() => {
    if (!currentConversation && !activeSession && open) {
      initializeSession();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversation, activeSession, open]);

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { height: '100%' } }}
    >
      <CasaAIHeader />
      <Divider />
      <DialogContent
        sx={{ height: '100%', px: 0 }}
        className={styles.dialogContentRoot}
      >
        <CasaAIContent />
      </DialogContent>
    </Dialog>
  );
};

export default CasaAIDialog;
