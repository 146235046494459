import { Line } from 'react-chartjs-2';
import React, {FC} from "react";
import {Box} from "@mui/material";
import {map} from "lodash";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
} from 'chart.js';
import {beautifyHeader} from "../../../../utils/TextModifier";
interface LineChartProps {
  data: any;
  axes: {
    x: string;
    y: string;
  };
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);
export const MessageVisualizationLine: FC<LineChartProps> = ({data, axes}) => {
  const labels: string[] = map(data, axes?.x);
  const dataset = map(data, axes?.y);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      }
    }
  }
  const barData = {
    labels,
    datasets: [{
      label: beautifyHeader(axes?.x),
      data: dataset,
      borderWidth: 1
    }]
  }
  return (
    <Box>
      <Line options={options} data={barData} />
    </Box>
  )
}