import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const OverviewDashboard = lazy(() => import('../components/OverviewDashboard'));

const overviewDashboardRoute: RouteObject = {
    index: true,
    element: <OverviewDashboard />,
};

export default overviewDashboardRoute;
