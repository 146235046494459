import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';

import CasaLoader from '../../../../shared/components/CasaLoader/CasaLoader';
import useCasaAI from '../../hooks/useCasaAI';
import CasaAIFooter from '../CasaAIFooter/CasaAIFooter';
import { CasaAIHistory } from '../CasaAIHistory/CasaAIHistory';
import CasaAIInfo from '../CasaAIInfo/CasaAIInfo';
import Conversation from '../Conversation/Conversation';
import styles from './CasaAIContent.module.scss';

const CasaAIContent = () => {
  const { currentConversation, loadMessagesBySessionAndPage, activeSession } =
    useCasaAI();
  const conversationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop =
        conversationRef.current.scrollHeight + 3000;
    }
  }, [currentConversation]);

  const getConversationComponent = () => {
    if (!currentConversation) return <CasaLoader />;
    if (currentConversation!.messages.length === 0) return <CasaAIInfo />;
    return (
      <Conversation
        {...currentConversation!}
        loadMessagesBySessionAndPage={loadMessagesBySessionAndPage}
        activeSession={activeSession}
      />
    );
  };

  return (
    <Box style={{ height: '100%' }} className={styles.dialogContent}>
      <CasaAIHistory />
      <Box className={styles.conversationContainer}>
        <Box className={styles.conversation} ref={conversationRef}>
          {getConversationComponent()}
        </Box>
        <CasaAIFooter />
      </Box>
    </Box>
  );
};

export default CasaAIContent;
