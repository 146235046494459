import { Box, Button, InputBase } from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import Search from '../../../../shared/icons/Search';
import styles from './InstantSearchHeader.module.scss';

interface InstantSearchHeaderProps {
  searchTerm: string;
  onSearchTermChange: (value: string) => void;
  onClose: () => void;
}

const InstantSearchHeader: FC<InstantSearchHeaderProps> = ({
  searchTerm,
  onSearchTermChange,
  onClose,
}) => {
  const searchElementRef = useRef<HTMLInputElement | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchTermChange(event.target.value);
  };

  useEffect(() => {
    searchElementRef.current?.focus();
  }, []);

  return (
    <Box component='header' id={styles.instantSearchHeader}>
      <Search color='primary' />
      <InputBase
        value={searchTerm}
        placeholder='Search...'
        inputRef={searchElementRef}
        fullWidth
        onChange={handleSearchChange}
        autoComplete='false'
        className={styles.searchFormControl}
      />
      <Button
        className={styles.escBtn}
        variant='outlined'
        color='secondary'
        size='small'
        onClick={onClose}
      >
        esc
      </Button>
    </Box>
  );
};

export default InstantSearchHeader;
