import React from 'react';

interface CasaLogoProps {
  width?: number;
  height?: number;
  className?: string;
}

const CasaLogo = ({ width = 95, height = 25, className }: CasaLogoProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 96 25'
      fill='none'
    >
      <g clipPath='url(#clip0_3_2)'>
        <path
          d='M12.0853 0.180186V24.3858H11.8669C8.68607 24.3548 5.64782 23.0616 3.4204 20.7906C1.19297 18.5197 -0.0412011 15.457 -0.0106775 12.2761C-0.0412011 9.09531 1.19297 6.0326 3.4204 3.76164C5.64782 1.49069 8.68607 0.197479 11.8669 0.166443L12.0853 0.180186Z'
          fill='#F2755F'
        />
        <path
          d='M12.0715 6.87333V17.6911H11.8532C10.4326 17.6673 9.07822 17.0862 8.08203 16.0731C7.08585 15.0601 6.52759 13.6962 6.52759 12.2754C6.52759 10.8545 7.08585 9.49064 8.08203 8.47758C9.07822 7.46452 10.4326 6.88344 11.8532 6.85959L12.0715 6.87333Z'
          fill='#FACCC3'
        />
        <path
          d='M27.3957 0.166443C30.5765 0.197479 33.6148 1.49069 35.8422 3.76164C38.0696 6.03259 39.3038 9.09529 39.2732 12.2761C39.2732 15.2203 39.3282 24.4408 39.3282 24.4408C39.3282 24.4408 31.0729 24.3858 27.3957 24.3858C24.2149 24.3548 21.1766 23.0616 18.9492 20.7906C16.7218 18.5197 15.4876 15.457 15.5181 12.2761C15.4876 9.09529 16.7218 6.03259 18.9492 3.76164C21.1766 1.49069 24.2149 0.197479 27.3957 0.166443V0.166443Z'
          fill='#F2755F'
        />
        <path
          d='M27.3957 17.6927C30.3298 17.6927 32.7084 15.2676 32.7084 12.2761C32.7084 9.28465 30.3298 6.85959 27.3957 6.85959C24.4616 6.85959 22.083 9.28465 22.083 12.2761C22.083 15.2676 24.4616 17.6927 27.3957 17.6927Z'
          fill='#FACCC3'
        />
        <path
          d='M40.4186 12.0547H64.1478C64.155 12.0961 64.155 12.1385 64.1478 12.1799C64.1492 15.3732 62.8974 18.4395 60.6614 20.7193C59.5665 21.8353 58.2593 22.7209 56.8169 23.3241C55.3745 23.9273 53.826 24.2359 52.2625 24.2316C50.693 24.2234 49.1405 23.9057 47.6939 23.2966C46.2474 22.6876 44.9351 21.7992 43.8324 20.6822C42.7297 19.5653 41.8581 18.2419 41.2676 16.7876C40.6771 15.3334 40.3792 13.7769 40.3911 12.2074C40.394 12.1556 40.4032 12.1043 40.4186 12.0547Z'
          fill='#F2755F'
        />
        <path
          d='M46.9713 12.1646H57.5829V12.2334C57.5847 12.931 57.4491 13.6222 57.1837 14.2675C56.9184 14.9127 56.5286 15.4994 56.0366 15.994C55.5445 16.4886 54.9599 16.8815 54.316 17.1501C53.6721 17.4188 52.9816 17.558 52.284 17.5598C51.5863 17.5616 50.8951 17.426 50.2498 17.1606C49.6046 16.8953 49.0179 16.5055 48.5233 16.0135C48.0287 15.5214 47.6359 14.9368 47.3672 14.2929C47.0985 13.649 46.9593 12.9585 46.9575 12.2608C46.9647 12.2292 46.9693 12.197 46.9713 12.1646V12.1646Z'
          fill='#FACCC3'
        />
        <path
          d='M46.6719 12.0547H70.4011V12.0119C70.4065 8.86343 69.161 5.8417 66.9385 3.61151C64.7161 1.38131 61.6987 0.125328 58.5502 0.11986C55.4017 0.114393 52.38 1.35989 50.1498 3.58235C47.9196 5.80482 46.6636 8.82219 46.6581 11.9707C46.6581 12.0394 46.6581 12.0547 46.6719 12.0547Z'
          fill='#F2755F'
        />
        <path
          d='M53.2246 12.1646H63.8363V12.0684C63.8349 10.6452 63.2732 9.27968 62.2726 8.26754C61.7828 7.77224 61.1986 7.38026 60.5547 7.11481C59.9107 6.84936 59.2201 6.71583 58.5236 6.72214C57.8245 6.7189 57.1317 6.8543 56.4853 7.12053C55.8388 7.38675 55.2516 7.7785 54.7576 8.27312C54.2635 8.76775 53.8724 9.35543 53.6069 10.0022C53.3415 10.6489 53.2068 11.3418 53.2109 12.0409C53.2091 12.0826 53.2138 12.1243 53.2246 12.1646V12.1646Z'
          fill='#FACCC3'
        />
        <path
          d='M83.6668 0.166443C86.8476 0.197479 89.8859 1.49069 92.1133 3.76164C94.3407 6.03259 95.5749 9.09529 95.5444 12.2761C95.5444 15.2203 95.5994 24.4408 95.5994 24.4408C95.5994 24.4408 87.344 24.3858 83.6668 24.3858C80.486 24.3548 77.4477 23.0616 75.2203 20.7906C72.9928 18.5197 71.7587 15.457 71.7892 12.2761C71.7587 9.09529 72.9928 6.03259 75.2203 3.76164C77.4477 1.49069 80.486 0.197479 83.6668 0.166443V0.166443Z'
          fill='#F2755F'
        />
        <path
          d='M83.6668 17.6927C86.6009 17.6927 88.9794 15.2676 88.9794 12.2761C88.9794 9.28465 86.6009 6.85959 83.6668 6.85959C80.7326 6.85959 78.3541 9.28465 78.3541 12.2761C78.3541 15.2676 80.7326 17.6927 83.6668 17.6927Z'
          fill='#FACCC3'
        />
      </g>
      <defs>
        <clipPath id='clip0_3_2'>
          <rect width='95.61' height='24.4332' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CasaLogo;
