import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const IntellicloseConfig = lazy(
  () => import("../components/IntellicloseConfig")
);
const LeadCreationConfig = lazy(
  () => import("../components/LeadCreationConfig/LeadCreationConfig")
);
const LeadManageConfig = lazy(
  () => import("../components/LeadManageConfig/LeadManageConfig")
);

const intellicloseConfigRoute: RouteObject = {
  path: "settings/modules/intelliclose",
  element: <IntellicloseConfig />,
  children: [
    {
      index: true,
      element: <LeadCreationConfig />,
    },
    {
      path: "manage-config",
      element: <LeadManageConfig />,
    },
  ],
};

export default intellicloseConfigRoute;
