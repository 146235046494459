import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Lead = lazy(() => import('../components/CreateLead'));

const leadConfigRoute: RouteObject = {
  path: '/leads/create',
  element: <Lead />,
};

export default leadConfigRoute;
