import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { FeedbackSummary } from "../interfaces/Feedback";
interface ActivityLogCount {
  transactions: string;
  leads: string;
  services: string;
  feedbacks: string;
  complaints: string;
}
interface Recommendations {
  product: string;
  score: number;
  image: string;
}
interface Customer360 {
  leads: Record<string, any>;
  cartItems: Record<string, any>;
  productViews: Record<string, any>;
  recommendations: Record<string, any>;
  serviceData: Record<string, any>;
  feedbackSummary: Record<string, any>;
  feedbackList: Record<string, any>;
  complaints: Record<string, any>;
  loyaltyDetails: Record<string, any>;
  activityLogCount: Record<string, any>;
  recentOtp: Record<string, any>;
  offers: Record<string, any>;
  milestoneDetails: Record<string, any>;
  setMilestoneDetails: (milestoneDetails: Record<string, any>) => void;

  setLeads: (lead: Record<string, any>) => void;
  setCartItems: (cartItem: Record<string, any>) => void;
  setProductViews: (productView: Record<string, any>) => void;
  setRecommendations: (recommendation: Record<string, any>) => void;
  setServiceData: (serviceData: Record<string, any>) => void;
  setFeedbackSummary: (feedback: Record<string, any>) => void;
  setFeedbackList: (feedback: Record<string, any>) => void;
  setComplaints: (complaint: Record<string, any>) => void;
  setLoyaltyDetails: (loyaltyDetails: Record<string, any>) => void;
  setActivityLogCount: (activityLogCount: Record<string, any>) => void;
  setRecentOtp: (recentOtp: Record<any, any>) => void;
  setOffers: (offers: Record<string, any>) => void;
  refresh: () => void;
}

const CustomerContext = createContext<Customer360>({
  milestoneDetails: {},
  setMilestoneDetails: () => {},
  leads: {},
  cartItems: {},
  productViews: {},
  recommendations: {},
  serviceData: {},
  feedbackSummary: {},
  feedbackList: {},
  complaints: {},
  loyaltyDetails: {},
  activityLogCount: {},
  recentOtp: {},
  offers: {},

  setLeads: () => {},
  setCartItems: () => {},
  setProductViews: () => {},
  setRecommendations: () => {},
  setServiceData: () => {},
  setFeedbackSummary: () => {},
  setFeedbackList: () => {},
  setComplaints: () => {},
  setLoyaltyDetails: () => {},
  setActivityLogCount: () => {},
  setRecentOtp: () => { },
  setOffers: () => {},
  refresh: () => {},
});

export const CustomerContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [milestoneDetails, setMilestoneDetails] = useState<Record<string, any>>({});
  const [leads, setLeads] = useState<Record<string, any>>({});
  const [cartItems, setCartItems] = useState<Record<string, any>>({});
  const [productViews, setProductViews] = useState<Record<string, any>>({});
  const [recommendations, setRecommendations] = useState<
    Record<string, Recommendations[]>
  >({});
  const [serviceData, setServiceData] = useState<Record<string, any>>({});
  const [complaints, setComplaints] = useState<Record<string, any>>({});
  const [loyaltyDetails, setLoyaltyDetails] = useState<Record<string, any>>({});
  const [feedbackSummary, setFeedbackSummary] = useState<
    Record<string, FeedbackSummary[]>
  >({});
  const [feedbackList, setFeedbackList] = useState<Record<string, any>>({});
  const [activityLogCount, setActivityLogCount] = useState<
    Record<string, ActivityLogCount>
  >({});
  const [recentOtp, setRecentOtp] = useState<Record<string, any>>({});
  const [offers, setOffers] = useState<Record<string, any>>({});
  const refresh = () => {
    setMilestoneDetails({});
    setLeads({});
    setCartItems({});
    setProductViews({});
    setRecommendations({});
    setServiceData({});
    setComplaints({});
    setLoyaltyDetails({});
    setFeedbackSummary({});
    setFeedbackList({});
    setActivityLogCount({});
    setRecentOtp({});
    setOffers({});
  };

  return (
    <CustomerContext.Provider
      value={{
        milestoneDetails,
        setMilestoneDetails,
        leads,
        setLeads,
        cartItems,
        setCartItems,
        productViews,
        setProductViews,
        recommendations,
        setRecommendations,
        serviceData,
        setServiceData,
        complaints,
        setComplaints,
        loyaltyDetails,
        setLoyaltyDetails,
        feedbackSummary,
        setFeedbackSummary,
        feedbackList,
        setFeedbackList,
        activityLogCount,
        setActivityLogCount,
        recentOtp,
        setRecentOtp,
        offers,
        setOffers,
        refresh,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

const useCustomer360 = () => {
  return useContext(CustomerContext);
};

export { useCustomer360, CustomerContext };
