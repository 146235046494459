import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const UploadData = lazy(() => import('../components/UploadData'));

const uploadDataRoutes: RouteObject = {
  path: '/data-upload',
  element: <UploadData />,
};

export default uploadDataRoutes;
