import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CustomerCubeSettings = lazy(
  () => import('../components/CustomerCubeSettings')
);
const RFMConfig = lazy(() => import('../../RFMConfig/components/RFMConfig'));
const CasaFieldConfig = lazy(
  () => import('../../CasaFields/components/TenantFields')
);
const FestivalSettings = lazy(
  () => import('../../FestivalSettings/components/FestivalSettings')
);
const KPIReportConfig = lazy(
  () => import('../../KPIConfig/components/KPIConfig'));
const ProductRecommendationSettings = lazy(
  () =>
    import(
      '../../ProductRecommendationSettings/components/ProductRecommendationSettings'
    )
);

const customerCubeSettingsRoute: RouteObject = {
  path: '/settings/module/customer-cube',
  element: <CustomerCubeSettings />,
  children: [
    {
      index: true,
      element: <RFMConfig />,
    },
    {
      path: 'field-mapping',
      element: <CasaFieldConfig />,
    },
    {
      path: 'festival-settings',
      element: <FestivalSettings />,
    },
    {
      path: 'customer-lifecycle',
      element: <KPIReportConfig />,
    },
    {
      path: 'product-recommendation',
      element: <ProductRecommendationSettings />,
    },
  ],
};

export default customerCubeSettingsRoute;
