import { Box, LinearProgress } from '@mui/material';
import React from 'react';

import CasaLogo from '../../logo/CasaLogo';
import styles from './CasaLoader.module.scss';

const CasaLoader = () => {
  return (
    <Box id={styles.casaLoader}>
      <CasaLogo width={200} height={100} />
      <LinearProgress className={styles.linearProgress} />
    </Box>
  );
};

export default CasaLoader;
