import { createSvgIcon } from '@mui/material';

const Personify = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M5.5 25C5.5 25 4 25 4 23.5C4 22 5.5 17.5 13 17.5C21.7278 17.5 17 22.5 17 24C17 25.5 15.5 25 15.5 25H5.5ZM13 16C14.1935 16 15.3381 15.5259 16.182 14.682C17.0259 13.8381 17.5 12.6935 17.5 11.5C17.5 10.3065 17.0259 9.16193 16.182 8.31802C15.3381 7.47411 14.1935 7 13 7C11.8065 7 10.6619 7.47411 9.81802 8.31802C8.97411 9.16193 8.5 10.3065 8.5 11.5C8.5 12.6935 8.97411 13.8381 9.81802 14.682C10.6619 15.5259 11.8065 16 13 16Z'
      fill='currentColor'
    />
    <circle cx='20' cy='21' r='6' fill='white' />
    <path
      d='M21 15C17.6915 15 15 17.6985 15 21.0155C15.3295 28.996 26.6717 28.9937 27 21.0155C27 17.6985 24.3085 15 21 15ZM24 21.6045V23.5936C24 23.8309 23.8083 24.0233 23.5714 24.0233H18.4286C18.1917 24.0233 18 23.8309 18 23.5936V21.6045C17.6726 21.3225 17.4445 20.8747 17.4953 20.4098L17.936 18.3474C17.9782 18.1494 18.1528 18.0078 18.3549 18.0078H23.6451C23.8472 18.0078 24.0218 18.1494 24.064 18.3474L24.5047 20.4098C24.5557 20.8747 24.3273 21.3225 24 21.6045Z'
      fill='currentColor'
    />
    <path
      d='M18.7012 18.8672L18.3438 20.5393C18.3643 20.8528 18.625 21.1016 18.9427 21.1016C19.2733 21.1016 19.5424 20.8316 19.5424 20.4999C19.5424 20.2626 19.7341 20.0702 19.971 20.0702C20.2079 20.0702 20.3996 20.2626 20.3996 20.4999C20.3996 20.8316 20.6687 21.1016 20.9997 21.1016C21.3308 21.1016 21.5999 20.8316 21.5999 20.4999C21.5999 20.2626 21.7916 20.0702 22.0285 20.0702C22.2653 20.0702 22.457 20.2626 22.457 20.4999C22.457 20.8316 22.7261 21.1016 23.0568 21.1016C23.3744 21.1016 23.6352 20.8528 23.6557 20.5393L23.2983 18.8672H18.7012Z'
      fill='currentColor'
    />
    <path
      d='M19.9696 21.5332C19.6905 21.8111 19.277 21.995 18.8555 21.9517V23.1634H23.1412V21.9517C22.7198 21.9951 22.306 21.8111 22.0271 21.5332C21.5105 22.0941 20.4862 22.0942 19.9696 21.5332Z'
      fill='currentColor'
    />
  </svg>,
  'Personify'
);

export default Personify;
