import { Box, Container } from '@mui/material';
import React, { Suspense, useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import PageLoader from '../../../shared/components/PageLoader/PageLoader';
import styles from './Dashboard.module.scss';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import CasaAI from '../../../features/CasaAI/components/CasaAI';
import { classnames } from '../../../utils';
import useNavigationLink from '../../../hooks/useNavigationLink';

const Dashboard = () => {
  const { fullScreenMode } = useNavigationLink();
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const handleToggleSidebar = (): void => {
    setIsSidebarActive((isSidebarActive) => !isSidebarActive);
  };

  return (
    <Box id={styles.dashboard}>
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <Box
        className={classnames(styles.app, {
          [styles.fullScreenMode]: fullScreenMode,
        })}
      >
        <Sidebar
          isSidebarActive={isSidebarActive}
          onCloseSidebar={setIsSidebarActive.bind(null, false)}
        />
        <Container component='main' className={styles.container}>
          <Suspense fallback={<PageLoader />}>
            <Outlet />
          </Suspense>
        </Container>
      </Box>
      <ScrollRestoration />
      <CasaAI />
    </Box>
  );
};

export default Dashboard;
