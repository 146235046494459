import React from 'react';
import { Outlet } from 'react-router-dom';

const PageGuard = () => {
  // const { allowedPages } = useNavigationLink();
  // const { pathname } = useLocation();

  // console.log(pathname);

  // if (pathname === '/' || !pathname) return <Outlet />;

  // //TODO! Need to Protect extra

  // const isAllowed = !!allowedPages.find((pagePattern) =>
  //   matchPath(pagePattern, pathname)
  // );

  // if (!isAllowed) return <>Not Allowed</>;

  return <Outlet />;
};

export default PageGuard;
