import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import { Dictionary } from 'lodash'
import keycloak from './keycloak'
import { isAuthErrored } from './utils/isAuthError'

const setAuthorization = (token: string) =>
  (axios.defaults.headers.common['Authorization'] = `Bearer ${token}`)

// axios.defaults.baseURL = config.ruleServerUrl; // TODO: Need to switch everything api Server
const init = ({ token }: Dictionary<string>) => {
  axiosRetry(axios, {
    retries: 2,
    retryCondition: async (error: AxiosError) => {
      if (isAuthErrored(error as AxiosError<Dictionary<string>>)) {
        keycloak.onTokenExpired && keycloak.onTokenExpired()
        return true
      }
      return false
    },
  })
  setAuthorization(token)
}

const Axios = {
  init,
  setAuthorization,
}

export default Axios
