import { Avatar, IconButton, Tooltip } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import useAuth from '../../../../hooks/useAuth';

import UserAccountMenu from '../UserAccountMenu/UserAccountMenu';

const UserAccount = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { currentUser } = useAuth();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        title={_.capitalize(currentUser?.tenant.displayName)}
        sx={{ p: 0 }}
      >
        <IconButton onClick={handleClick} size='small'>
          <Avatar sx={{ width: 40, height: 40 }}>
            {currentUser?.email.slice(0, 1).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <UserAccountMenu
        user={currentUser}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default UserAccount;
