import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const CopyWriteTemplate = lazy(() => import('../components/CopyWriteTemplate'));

const CopyWriteTemplateRoutes: RouteObject = {
  path: '/campaign/copy-write/templates',
  element: <CopyWriteTemplate />,
};

export default CopyWriteTemplateRoutes;
