import { createSvgIcon } from '@mui/material';

const DigitalReceipt = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      d='M4 7C4 6.20435 4.31607 5.44129 4.87868 4.87868C5.44129 4.31607 6.20435 4 7 4H19.6667C20.4623 4 21.2254 4.31607 21.788 4.87868C22.3506 5.44129 22.6667 6.20435 22.6667 7V18.6667H28V23.6667C28 24.8159 27.5435 25.9181 26.7308 26.7308C25.9181 27.5435 24.8159 28 23.6667 28H8.33333C7.18406 28 6.08186 27.5435 5.2692 26.7308C4.45655 25.9181 4 24.8159 4 23.6667V7ZM22.6667 26H23.6667C24.2855 26 24.879 25.7542 25.3166 25.3166C25.7542 24.879 26 24.2855 26 23.6667V20.6667H22.6667V26ZM8.66667 10.3333C8.66667 10.8853 9.11467 11.3333 9.66667 11.3333H17C17.2652 11.3333 17.5196 11.228 17.7071 11.0404C17.8946 10.8529 18 10.5985 18 10.3333C18 10.0681 17.8946 9.81376 17.7071 9.62623C17.5196 9.43869 17.2652 9.33333 17 9.33333H9.66667C9.40145 9.33333 9.1471 9.43869 8.95956 9.62623C8.77202 9.81376 8.66667 10.0681 8.66667 10.3333ZM9.66667 14.6667C9.40145 14.6667 9.1471 14.772 8.95956 14.9596C8.77202 15.1471 8.66667 15.4015 8.66667 15.6667C8.66667 15.9319 8.77202 16.1862 8.95956 16.3738C9.1471 16.5613 9.40145 16.6667 9.66667 16.6667H17C17.2652 16.6667 17.5196 16.5613 17.7071 16.3738C17.8946 16.1862 18 15.9319 18 15.6667C18 15.4015 17.8946 15.1471 17.7071 14.9596C17.5196 14.772 17.2652 14.6667 17 14.6667H9.66667ZM8.66667 21C8.66667 21.552 9.11467 22 9.66667 22H13.6667C13.9319 22 14.1862 21.8946 14.3738 21.7071C14.5613 21.5196 14.6667 21.2652 14.6667 21C14.6667 20.7348 14.5613 20.4804 14.3738 20.2929C14.1862 20.1054 13.9319 20 13.6667 20H9.66667C9.40145 20 9.1471 20.1054 8.95956 20.2929C8.77202 20.4804 8.66667 20.7348 8.66667 21Z'
      fill='currentColor'
    />
  </svg>,
  'DigitalReceipt'
);

export default DigitalReceipt;
