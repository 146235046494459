import { createSvgIcon } from '@mui/material';

const Retensee = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.5 16.5C19.6365 16.5 16.5 19.6365 16.5 23.5C16.5 27.3635 19.6365 30.5 23.5 30.5C27.3635 30.5 30.5 27.3635 30.5 23.5C30.5 19.6365 27.3635 16.5 23.5 16.5ZM23.5 17.5C26.8115 17.5 29.5 20.1885 29.5 23.5C29.5 26.8115 26.8115 29.5 23.5 29.5C20.1885 29.5 17.5 26.8115 17.5 23.5C17.5 20.1885 20.1885 17.5 23.5 17.5Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.9886 27.7229C18.1941 27.8239 18.4421 27.7724 18.5906 27.5974C18.7386 27.4229 18.7491 27.1699 18.6156 26.9834C17.9136 26.0014 17.5001 24.7984 17.5001 23.4999C17.5001 20.1884 20.1886 17.4999 23.5001 17.4999C24.5571 17.4999 25.5511 17.7739 26.4136 18.2544C26.6111 18.3649 26.8581 18.3284 27.0156 18.1659L29.6781 15.4239C30.4296 14.6504 30.6911 13.5244 30.3576 12.4984C30.0246 11.4729 29.1511 10.7159 28.0886 10.5314L22.5566 9.57138C21.9736 9.47038 21.4711 9.10488 21.1951 8.58188C21.1946 8.58188 18.5721 3.61738 18.5721 3.61738C18.0686 2.66388 17.0786 2.06738 16.0001 2.06738C14.9216 2.06738 13.9316 2.66388 13.4281 3.61738L10.8056 8.58188C10.5291 9.10488 10.0266 9.47038 9.4436 9.57138L3.9116 10.5314C2.8491 10.7159 1.9756 11.4729 1.6426 12.4984C1.3091 13.5244 1.5706 14.6504 2.3221 15.4239L6.2331 19.4524C6.6451 19.8764 6.8371 20.4674 6.7531 21.0529L5.9571 26.6109C5.8041 27.6784 6.2541 28.7429 7.1266 29.3769C7.9991 30.0109 9.1506 30.1099 10.1186 29.6349L15.1586 27.1599C15.6891 26.8989 16.3111 26.8989 16.8416 27.1599L17.9886 27.7229Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.4316 22.6573C25.1211 22.6573 24.8421 22.4683 24.7276 22.1798C24.6056 21.8733 24.4651 21.5203 24.3381 21.2013C24.2016 20.8578 23.8696 20.6328 23.5001 20.6328C23.1306 20.6328 22.7986 20.8578 22.6621 21.2013C22.5351 21.5203 22.3946 21.8733 22.2726 22.1798C22.1581 22.4683 21.8791 22.6573 21.5686 22.6573C21.1431 22.6573 20.7336 22.4963 20.4216 22.2068L19.9691 21.7863C19.7146 21.5498 19.3376 21.5003 19.0306 21.6638C18.7236 21.8268 18.5536 22.1668 18.6076 22.5103C18.7056 23.1358 18.8371 23.9773 18.9461 24.6713C19.0991 25.6478 19.9406 26.3678 20.9291 26.3678H26.0711C27.0596 26.3678 27.9011 25.6478 28.0541 24.6713C28.1631 23.9773 28.2946 23.1358 28.3926 22.5103C28.4466 22.1668 28.2766 21.8268 27.9696 21.6638C27.6626 21.5003 27.2856 21.5498 27.0311 21.7863L26.5786 22.2068C26.2666 22.4963 25.8571 22.6573 25.4316 22.6573Z'
      fill='currentColor'
    />
  </svg>,
  'Retensee'
);

export default Retensee;
