import { Logout, Settings } from '@mui/icons-material';
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import useUser from '../../../../hooks/useUser';
import User from '../../../../interfaces/User';
import { logout } from '../../../../keycloak';
import Permissions from '../../../../shared/enums/Permissions';
import { classnames } from '../../../../utils';
import styles from './UserAccountMenu.module.scss';

interface UserAccountMenuProps {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
  user: User | null;
}

const UserAccountMenu: FC<UserAccountMenuProps> = ({
  user,
  anchorEl,
  open,
  onClose,
}) => {
  const { userPermissions } = useUser();
  const hasSettingsPermission = userPermissions.includes(
    Permissions.CONFIG_MANAGE
  );
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClick={onClose}
      onClose={onClose}
      id={styles.userAccountMenu}
      slotProps={{
        paper: {
          sx: { minWidth: 220, px: 1 },
        },
      }}
    >
      <Box className={styles.userInfo} component='li'>
        <Typography fontWeight={500}>
          {_.capitalize(user?.tenant.displayName)}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {user?.email}
        </Typography>
      </Box>
      {hasSettingsPermission && <Divider />}
      {hasSettingsPermission && (
        <MenuItem component={Link} to='/settings' className={styles.menuItem}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          <ListItemText primary={'Settings'} />
        </MenuItem>
      )}
      <Divider />
      <MenuItem
        className={classnames(styles.menuItem, styles.logoutMenu)}
        onClick={logout}
      >
        <ListItemIcon>
          <Logout fontSize='small' color='error' />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: 'error',
          }}
          color='error'
          primary={'Logout'}
        />
      </MenuItem>
    </Menu>
  );
};

export default UserAccountMenu;
